
import fb from '../../images/fullBody2Edit.png';

const AboutUs = () => {
  return (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'wrap',
        justifyContent: 'space-evenly',
        padding: 15,
      }}>
          <div style={{ textAlign: 'justify', maxWidth: 500}}>
          <h4>Lawrence Ng</h4>
            <br />
            From PREEMINENT GROUP, the No. 1 Group in ERA Realty Network Pte Ltd. I find passion in dealing with real estate and helping others find their dream home.
            <br />
            <br />
            I have firmly knowledge on fundamental analysis, and well-equipped with financial intelligence, I am able to provide very valuable feedbacks on various property inquiries. 
            Strategic estate planning have always been my core belief and have served many of my client well.
            <br />
            <br />
            I strongly believe that when it comes to property, choosing the right property today will serve a good foundation for the future. 
            Likewise, I have meet countless client that could have been in a better position for an upgrade or retirement for their property if they had made a better decision from the start. 
            As always, the common thinking that others have will net you common results that others have too in the future.
            <br />
            <br />
            Call me today for a friendly chat!
            <br />
            <br />
            <br />
            <div style={{ color: 'grey', fontSize: 18, fontWeight: 600, fontStyle: 'italic'}}>
              Lawrence Ng
              <br />
              8484 5999
            </div>
          </div>
          <img
          style={{ height: 500}}
            src={fb}
            alt="Lawrence">
          </img>
      </div>
  );
}

export default AboutUs;
