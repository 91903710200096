import React from 'react';
import { useTable } from 'react-table'
import { fiveRm } from '../../data/bedokHdbTransaction';

const Table = ({ columns, data }: any) => {

    const {
        headerGroups,
        rows,
        prepareRow,
      } = useTable({
        columns,
        data,
      });

      return (
        <table style={{
            borderSpacing: 0,
            border: '1px solid black',
            alignSelf: 'center',
            marginTop: 45,
        }}>
            <thead>
            {headerGroups.map((headerGroup: any, id: any) => (
                <tr key={id} style={{
                    margin: 0,
                    padding: '0.5rem',
                    borderBottom: '1px solid black',
                    textAlign: 'center',
                }}>
                {headerGroup.headers.map((column: any) => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
                </tr>
            ))}
            </thead>
            <tbody>
            {rows.map((row: any, i: any) => {
                prepareRow(row)
                return (
                <tr key={i} >
                    {row.cells.map((cell: any, i: any) => {
                        return <td key={i} style={{
                            margin: 0,
                            padding: '0.5rem',
                            borderTop: '1px solid black',
                            borderBottom: '1px solid black',
                            borderRight: '1px solid black',
                        }}>{cell.render('Cell')}</td>
                    })}
                </tr>
                )
            })}
            </tbody>
        </table>
      )
}

const HdbBedok5rm = () => {
    const fiveRmColumns = React.useMemo(
        () => [
            {
            Header: '5 Room HDB',
            columns: [
                {
                Header: 'Town',
                accessor: 'Town',
                },
                {
                Header: 'StreetName',
                accessor: 'StreetName',
                },
                {
                Header: 'Model',
                accessor: 'Model',
                },
                {
                Header: 'Size',
                accessor: 'Size',
                },
                {
                Header: 'PSF',
                accessor: 'PSF',
                },
                {
                Header: 'Price',
                accessor: 'Price',
                },
                {
                Header: 'Date',
                accessor: 'Date',
                },
            ],
            },
        ],
        []
        );
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} >
            <div style={{ textAlign: 'center', fontFamily: 'Barlow', marginTop: 25, fontSize: 25 }}>BEDOK (5 Room)</div>
            <div style={{ textAlign: 'center', fontFamily: 'Barlow' }}>Recent Transcation</div>
            <Table columns={fiveRmColumns} data={fiveRm} />
        </div>
    );
};

export default HdbBedok5rm;
