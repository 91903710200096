export const threeRm = [
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 890A TAMPINES AVENUE 1",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "69",
        "PSF": "539",
        "Price": "400,000",
        "Date": "19-Mar-21",
        "PSM": "5,802"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 890A TAMPINES AVENUE 1",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "69",
        "PSF": "593",
        "Price": "440,000",
        "Date": "19-Mar-21",
        "PSM": "6,383"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 524B TAMPINES CENTRAL 7",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "67",
        "PSF": "583",
        "Price": "420,000",
        "Date": "17-Mar-21",
        "PSM": "6,275"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 262 TAMPINES STREET 21",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "452",
        "Price": "360,000",
        "Date": "17-Mar-21",
        "PSM": "4,865"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 283 TAMPINES STREET 22",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "427",
        "Price": "340,000",
        "Date": "17-Mar-21",
        "PSM": "4,596"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 407 TAMPINES STREET 41",
        "Type": "3-ROOM",
        "Model": "IMPROVED",
        "Size": "60",
        "PSF": "488",
        "Price": "315,000",
        "Date": "17-Mar-21",
        "PSM": "5,253"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 854 TAMPINES STREET 82",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "437",
        "Price": "348,000",
        "Date": "15-Mar-21",
        "PSM": "4,704"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 234 TAMPINES STREET 21",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "73",
        "PSF": "433",
        "Price": "340,000",
        "Date": "12-Mar-21",
        "PSM": "4,661"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 272 TAMPINES STREET 22",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "73",
        "PSF": "408",
        "Price": "320,000",
        "Date": "12-Mar-21",
        "PSM": "4,392"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 281 TAMPINES STREET 22",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "84",
        "PSF": "409",
        "Price": "370,000",
        "Date": "12-Mar-21",
        "PSM": "4,402"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 113 TAMPINES STREET 11",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "75",
        "PSF": "434",
        "Price": "350,000",
        "Date": "10-Mar-21",
        "PSM": "4,672"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 204 TAMPINES STREET 21",
        "Type": "3-ROOM",
        "Model": "IMPROVED",
        "Size": "60",
        "PSF": "496",
        "Price": "320,000",
        "Date": "10-Mar-21",
        "PSM": "5,339"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 269 TAMPINES STREET 21",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "81",
        "PSF": "429",
        "Price": "374,000",
        "Date": "8-Mar-21",
        "PSM": "4,618"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 898 TAMPINES STREET 81",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "415",
        "Price": "330,000",
        "Date": "8-Mar-21",
        "PSM": "4,467"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 520A TAMPINES CENTRAL 8",
        "Type": "3-ROOM",
        "Model": "DBSS",
        "Size": "61",
        "PSF": "686",
        "Price": "450,000",
        "Date": "5-Mar-21",
        "PSM": "7,384"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 250 TAMPINES STREET 21",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "78",
        "PSF": "441",
        "Price": "370,000",
        "Date": "5-Mar-21",
        "PSM": "4,747"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 291 TAMPINES STREET 22",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "73",
        "PSF": "401",
        "Price": "315,000",
        "Date": "5-Mar-21",
        "PSM": "4,316"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 828 TAMPINES STREET 81",
        "Type": "3-ROOM",
        "Model": "NEW GENERATION",
        "Size": "83",
        "PSF": "414",
        "Price": "370,000",
        "Date": "5-Mar-21",
        "PSM": "4,456"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 524B TAMPINES CENTRAL 7",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "67",
        "PSF": "605",
        "Price": "436,000",
        "Date": "3-Mar-21",
        "PSM": "6,512"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 462 TAMPINES STREET 44",
        "Type": "3-ROOM",
        "Model": "SIMPLIFIED",
        "Size": "64",
        "PSF": "465",
        "Price": "320,000",
        "Date": "3-Mar-21",
        "PSM": "5,005"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 225 TAMPINES STREET 23",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "73",
        "PSF": "465",
        "Price": "365,000",
        "Date": "26-Feb-21",
        "PSM": "5,005"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 437 TAMPINES STREET 43",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "418",
        "Price": "333,000",
        "Date": "26-Feb-21",
        "PSM": "4,499"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 494E TAMPINES STREET 43",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "67",
        "PSF": "527",
        "Price": "380,000",
        "Date": "26-Feb-21",
        "PSM": "5,673"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 520C TAMPINES CENTRAL 8",
        "Type": "3-ROOM",
        "Model": "DBSS",
        "Size": "61",
        "PSF": "739",
        "Price": "485,000",
        "Date": "24-Feb-21",
        "PSM": "7,955"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 204 TAMPINES STREET 21",
        "Type": "3-ROOM",
        "Model": "IMPROVED",
        "Size": "60",
        "PSF": "473",
        "Price": "305,000",
        "Date": "24-Feb-21",
        "PSM": "5,091"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 930 TAMPINES STREET 91",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "73",
        "PSF": "465",
        "Price": "365,000",
        "Date": "24-Feb-21",
        "PSM": "5,005"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 810 TAMPINES AVENUE 4",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "73",
        "PSF": "439",
        "Price": "345,000",
        "Date": "22-Feb-21",
        "PSM": "4,725"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 421 TAMPINES STREET 41",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "440",
        "Price": "350,000",
        "Date": "22-Feb-21",
        "PSM": "4,736"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 214 TAMPINES STREET 23",
        "Type": "3-ROOM",
        "Model": "NEW GENERATION",
        "Size": "73",
        "PSF": "382",
        "Price": "300,000",
        "Date": "19-Feb-21",
        "PSM": "4,112"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 212 TAMPINES STREET 23",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "73",
        "PSF": "446",
        "Price": "350,000",
        "Date": "17-Feb-21",
        "PSM": "4,801"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 221 TAMPINES STREET 24",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "73",
        "PSF": "417",
        "Price": "327,000",
        "Date": "17-Feb-21",
        "PSM": "4,489"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 524B TAMPINES CENTRAL 7",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "67",
        "PSF": "583",
        "Price": "420,000",
        "Date": "12-Feb-21",
        "PSM": "6,275"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 214 TAMPINES STREET 23",
        "Type": "3-ROOM",
        "Model": "NEW GENERATION",
        "Size": "67",
        "PSF": "455",
        "Price": "328,000",
        "Date": "10-Feb-21",
        "PSM": "4,898"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 214 TAMPINES STREET 23",
        "Type": "3-ROOM",
        "Model": "NEW GENERATION",
        "Size": "67",
        "PSF": "472",
        "Price": "340,000",
        "Date": "10-Feb-21",
        "PSM": "5,081"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 868C TAMPINES AVENUE 8",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "67",
        "PSF": "569",
        "Price": "410,000",
        "Date": "5-Feb-21",
        "PSM": "6,125"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 246 TAMPINES STREET 21",
        "Type": "3-ROOM",
        "Model": "NEW GENERATION",
        "Size": "67",
        "PSF": "455",
        "Price": "328,000",
        "Date": "5-Feb-21",
        "PSM": "4,898"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 448 TAMPINES STREET 42",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "438",
        "Price": "349,000",
        "Date": "5-Feb-21",
        "PSM": "4,715"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 463 TAMPINES STREET 44",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "422",
        "Price": "336,000",
        "Date": "5-Feb-21",
        "PSM": "4,542"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 826 TAMPINES STREET 81",
        "Type": "3-ROOM",
        "Model": "NEW GENERATION",
        "Size": "67",
        "PSF": "430",
        "Price": "310,000",
        "Date": "5-Feb-21",
        "PSM": "4,629"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 854 TAMPINES STREET 82",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "484",
        "Price": "385,000",
        "Date": "5-Feb-21",
        "PSM": "5,210"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 890A TAMPINES AVENUE 1",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "69",
        "PSF": "563",
        "Price": "418,000",
        "Date": "3-Feb-21",
        "PSM": "6,060"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 113 TAMPINES STREET 11",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "75",
        "PSF": "386",
        "Price": "311,500",
        "Date": "3-Feb-21",
        "PSM": "4,155"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 240 TAMPINES STREET 21",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "452",
        "Price": "360,000",
        "Date": "3-Feb-21",
        "PSM": "4,865"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 267 TAMPINES STREET 21",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "75",
        "PSF": "428",
        "Price": "345,000",
        "Date": "3-Feb-21",
        "PSM": "4,607"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 418 TAMPINES STREET 41",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "425",
        "Price": "338,000",
        "Date": "3-Feb-21",
        "PSM": "4,575"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 444 TAMPINES STREET 42",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "459",
        "Price": "365,000",
        "Date": "3-Feb-21",
        "PSM": "4,941"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 450 TAMPINES STREET 42",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "427",
        "Price": "340,000",
        "Date": "3-Feb-21",
        "PSM": "4,596"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 263 TAMPINES STREET 21",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "450",
        "Price": "358,000",
        "Date": "1-Feb-21",
        "PSM": "4,844"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 406 TAMPINES STREET 41",
        "Type": "3-ROOM",
        "Model": "SIMPLIFIED",
        "Size": "64",
        "PSF": "469",
        "Price": "322,888",
        "Date": "1-Feb-21",
        "PSM": "5,048"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 467 TAMPINES STREET 44",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "425",
        "Price": "338,000",
        "Date": "1-Feb-21",
        "PSM": "4,575"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 881 TAMPINES STREET 84",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "437",
        "Price": "348,000",
        "Date": "1-Feb-21",
        "PSM": "4,704"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 523B TAMPINES CENTRAL 7",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "67",
        "PSF": "562",
        "Price": "405,000",
        "Date": "29-Jan-21",
        "PSM": "6,049"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 845 TAMPINES STREET 83",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "399",
        "Price": "318,000",
        "Date": "29-Jan-21",
        "PSM": "4,295"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 853 TAMPINES STREET 83",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "450",
        "Price": "358,000",
        "Date": "29-Jan-21",
        "PSM": "4,844"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 803 TAMPINES AVENUE 4",
        "Type": "3-ROOM",
        "Model": "NEW GENERATION",
        "Size": "82",
        "PSF": "420",
        "Price": "370,000",
        "Date": "27-Jan-21",
        "PSM": "4,521"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 408 TAMPINES STREET 41",
        "Type": "3-ROOM",
        "Model": "IMPROVED",
        "Size": "69",
        "PSF": "465",
        "Price": "345,000",
        "Date": "25-Jan-21",
        "PSM": "5,005"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 819 TAMPINES STREET 81",
        "Type": "3-ROOM",
        "Model": "NEW GENERATION",
        "Size": "68",
        "PSF": "451",
        "Price": "330,000",
        "Date": "25-Jan-21",
        "PSM": "4,855"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 890B TAMPINES AVENUE 1",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "69",
        "PSF": "531",
        "Price": "393,888",
        "Date": "22-Jan-21",
        "PSM": "5,716"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 868A TAMPINES AVENUE 8",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "67",
        "PSF": "541",
        "Price": "390,000",
        "Date": "22-Jan-21",
        "PSM": "5,823"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 157 TAMPINES STREET 12",
        "Type": "3-ROOM",
        "Model": "NEW GENERATION",
        "Size": "68",
        "PSF": "492",
        "Price": "360,000",
        "Date": "22-Jan-21",
        "PSM": "5,296"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 226 TAMPINES STREET 23",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "73",
        "PSF": "427",
        "Price": "335,000",
        "Date": "22-Jan-21",
        "PSM": "4,596"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 405 TAMPINES STREET 41",
        "Type": "3-ROOM",
        "Model": "IMPROVED",
        "Size": "60",
        "PSF": "450",
        "Price": "290,000",
        "Date": "22-Jan-21",
        "PSM": "4,844"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 417 TAMPINES STREET 41",
        "Type": "3-ROOM",
        "Model": "IMPROVED",
        "Size": "69",
        "PSF": "451",
        "Price": "335,000",
        "Date": "22-Jan-21",
        "PSM": "4,855"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 139 SIMEI STREET 1",
        "Type": "3-ROOM",
        "Model": "SIMPLIFIED",
        "Size": "64",
        "PSF": "461",
        "Price": "317,000",
        "Date": "20-Jan-21",
        "PSM": "4,962"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 203 TAMPINES STREET 21",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "75",
        "PSF": "417",
        "Price": "336,888",
        "Date": "20-Jan-21",
        "PSM": "4,489"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 286 TAMPINES STREET 22",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "396",
        "Price": "315,000",
        "Date": "20-Jan-21",
        "PSM": "4,263"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 469 TAMPINES STREET 44",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "399",
        "Price": "318,000",
        "Date": "20-Jan-21",
        "PSM": "4,295"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 258 TAMPINES STREET 21",
        "Type": "3-ROOM",
        "Model": "NEW GENERATION",
        "Size": "67",
        "PSF": "469",
        "Price": "338,000",
        "Date": "18-Jan-21",
        "PSM": "5,048"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 520C TAMPINES CENTRAL 8",
        "Type": "3-ROOM",
        "Model": "DBSS",
        "Size": "61",
        "PSF": "762",
        "Price": "500,000",
        "Date": "15-Jan-21",
        "PSM": "8,202"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 206 TAMPINES STREET 21",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "75",
        "PSF": "421",
        "Price": "340,000",
        "Date": "15-Jan-21",
        "PSM": "4,532"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 421 TAMPINES STREET 41",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "442",
        "Price": "352,000",
        "Date": "15-Jan-21",
        "PSM": "4,758"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 132 SIMEI STREET 1",
        "Type": "3-ROOM",
        "Model": "SIMPLIFIED",
        "Size": "64",
        "PSF": "475",
        "Price": "327,000",
        "Date": "13-Jan-21",
        "PSM": "5,113"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 890B TAMPINES AVENUE 1",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "69",
        "PSF": "566",
        "Price": "420,000",
        "Date": "13-Jan-21",
        "PSM": "6,092"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 806 TAMPINES AVENUE 4",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "73",
        "PSF": "452",
        "Price": "355,000",
        "Date": "13-Jan-21",
        "PSM": "4,865"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 868A TAMPINES AVENUE 8",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "67",
        "PSF": "555",
        "Price": "399,885",
        "Date": "13-Jan-21",
        "PSM": "5,974"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 523B TAMPINES CENTRAL 7",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "67",
        "PSF": "569",
        "Price": "410,000",
        "Date": "13-Jan-21",
        "PSM": "6,125"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 519C TAMPINES CENTRAL 8",
        "Type": "3-ROOM",
        "Model": "DBSS",
        "Size": "62",
        "PSF": "705",
        "Price": "470,000",
        "Date": "13-Jan-21",
        "PSM": "7,589"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 243 TAMPINES STREET 21",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "452",
        "Price": "360,000",
        "Date": "13-Jan-21",
        "PSM": "4,865"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 263 TAMPINES STREET 21",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "452",
        "Price": "360,000",
        "Date": "13-Jan-21",
        "PSM": "4,865"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 881 TAMPINES STREET 84",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "402",
        "Price": "320,000",
        "Date": "13-Jan-21",
        "PSM": "4,327"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 914 TAMPINES STREET 91",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "73",
        "PSF": "401",
        "Price": "315,000",
        "Date": "13-Jan-21",
        "PSM": "4,316"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 523B TAMPINES CENTRAL 7",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "67",
        "PSF": "580",
        "Price": "418,000",
        "Date": "11-Jan-21",
        "PSM": "6,243"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 444 TAMPINES STREET 42",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "425",
        "Price": "338,000",
        "Date": "11-Jan-21",
        "PSM": "4,575"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 806 TAMPINES AVENUE 4",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "73",
        "PSF": "459",
        "Price": "360,000",
        "Date": "8-Jan-21",
        "PSM": "4,941"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 869B TAMPINES AVENUE 8",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "67",
        "PSF": "548",
        "Price": "395,000",
        "Date": "8-Jan-21",
        "PSM": "5,899"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 215 TAMPINES STREET 23",
        "Type": "3-ROOM",
        "Model": "NEW GENERATION",
        "Size": "67",
        "PSF": "427",
        "Price": "308,000",
        "Date": "8-Jan-21",
        "PSM": "4,596"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 411 TAMPINES STREET 41",
        "Type": "3-ROOM",
        "Model": "IMPROVED",
        "Size": "69",
        "PSF": "418",
        "Price": "310,000",
        "Date": "8-Jan-21",
        "PSM": "4,499"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 877 TAMPINES STREET 84",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "402",
        "Price": "320,000",
        "Date": "8-Jan-21",
        "PSM": "4,327"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 898 TAMPINES STREET 81",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "425",
        "Price": "338,000",
        "Date": "6-Jan-21",
        "PSM": "4,575"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 243 TAMPINES STREET 21",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "74",
        "PSF": "427",
        "Price": "340,000",
        "Date": "1-Jan-21",
        "PSM": "4,596"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 255 TAMPINES STREET 21",
        "Type": "3-ROOM",
        "Model": "NEW GENERATION",
        "Size": "72",
        "PSF": "465",
        "Price": "360,000",
        "Date": "1-Jan-21",
        "PSM": "5,005"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 218 TAMPINES STREET 24",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "73",
        "PSF": "383",
        "Price": "301,000",
        "Date": "1-Jan-21",
        "PSM": "4,123"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 405 TAMPINES STREET 41",
        "Type": "3-ROOM",
        "Model": "IMPROVED",
        "Size": "69",
        "PSF": "472",
        "Price": "350,000",
        "Date": "1-Jan-21",
        "PSM": "5,081"
    }
];
export const fourRm = [
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 394 TAMPINES AVENUE 7",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "110",
        "PSF": "397",
        "Price": "469,800",
        "Date": "19-Mar-21",
        "PSM": "4,273"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 234 TAMPINES STREET 21",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "105",
        "PSF": "398",
        "Price": "450,000",
        "Date": "19-Mar-21",
        "PSM": "4,284"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 301 TAMPINES STREET 32",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "110",
        "PSF": "389",
        "Price": "460,000",
        "Date": "19-Mar-21",
        "PSM": "4,187"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 836 TAMPINES STREET 82",
        "Type": "4-ROOM",
        "Model": "SIMPLIFIED",
        "Size": "84",
        "PSF": "451",
        "Price": "408,000",
        "Date": "19-Mar-21",
        "PSM": "4,855"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 868 TAMPINES STREET 83",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "384",
        "Price": "430,000",
        "Date": "19-Mar-21",
        "PSM": "4,133"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 106 SIMEI STREET 1",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "429",
        "Price": "480,000",
        "Date": "17-Mar-21",
        "PSM": "4,618"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 946 TAMPINES AVENUE 4",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "451",
        "Price": "505,000",
        "Date": "17-Mar-21",
        "PSM": "4,855"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 518A TAMPINES CENTRAL 7",
        "Type": "4-ROOM",
        "Model": "DBSS",
        "Size": "92",
        "PSF": "616",
        "Price": "610,000",
        "Date": "17-Mar-21",
        "PSM": "6,631"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 522B TAMPINES CENTRAL 7",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "93",
        "PSF": "544",
        "Price": "545,000",
        "Date": "17-Mar-21",
        "PSM": "5,856"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 359 TAMPINES STREET 34",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "106",
        "PSF": "375",
        "Price": "428,000",
        "Date": "17-Mar-21",
        "PSM": "4,037"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 842B TAMPINES STREET 82",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "107",
        "PSF": "460",
        "Price": "530,000",
        "Date": "17-Mar-21",
        "PSM": "4,951"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 871B TAMPINES STREET 86",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "94",
        "PSF": "574",
        "Price": "580,000",
        "Date": "17-Mar-21",
        "PSM": "6,179"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 520A TAMPINES CENTRAL 8",
        "Type": "4-ROOM",
        "Model": "DBSS",
        "Size": "84",
        "PSF": "711",
        "Price": "643,000",
        "Date": "15-Mar-21",
        "PSM": "7,653"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 886A TAMPINES STREET 83",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "377",
        "Price": "422,000",
        "Date": "15-Mar-21",
        "PSM": "4,058"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 878B TAMPINES AVENUE 8",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "93",
        "PSF": "526",
        "Price": "527,000",
        "Date": "12-Mar-21",
        "PSM": "5,662"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 523C TAMPINES CENTRAL 7",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "93",
        "PSF": "549",
        "Price": "550,000",
        "Date": "12-Mar-21",
        "PSM": "5,909"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 519A TAMPINES CENTRAL 8",
        "Type": "4-ROOM",
        "Model": "DBSS",
        "Size": "84",
        "PSF": "747",
        "Price": "675,000",
        "Date": "12-Mar-21",
        "PSM": "8,041"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 519B TAMPINES CENTRAL 8",
        "Type": "4-ROOM",
        "Model": "DBSS",
        "Size": "84",
        "PSF": "695",
        "Price": "628,000",
        "Date": "12-Mar-21",
        "PSM": "7,481"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 272 TAMPINES STREET 22",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "103",
        "PSF": "384",
        "Price": "425,000",
        "Date": "12-Mar-21",
        "PSM": "4,133"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 284 TAMPINES STREET 22",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "103",
        "PSF": "376",
        "Price": "417,000",
        "Date": "12-Mar-21",
        "PSM": "4,047"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 710 TAMPINES STREET 71",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "100",
        "PSF": "404",
        "Price": "435,000",
        "Date": "12-Mar-21",
        "PSM": "4,349"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 710 TAMPINES STREET 71",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "101",
        "PSF": "405",
        "Price": "440,000",
        "Date": "12-Mar-21",
        "PSM": "4,359"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 931 TAMPINES STREET 91",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "102",
        "PSF": "401",
        "Price": "440,000",
        "Date": "12-Mar-21",
        "PSM": "4,316"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 155 SIMEI ROAD",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "386",
        "Price": "432,000",
        "Date": "10-Mar-21",
        "PSM": "4,155"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 868B TAMPINES AVENUE 8",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "92",
        "PSF": "561",
        "Price": "555,000",
        "Date": "10-Mar-21",
        "PSM": "6,039"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 485B TAMPINES AVENUE 9",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "384",
        "Price": "430,000",
        "Date": "10-Mar-21",
        "PSM": "4,133"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 524B TAMPINES CENTRAL 7",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "93",
        "PSF": "568",
        "Price": "568,888",
        "Date": "10-Mar-21",
        "PSM": "6,114"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 289 TAMPINES STREET 22",
        "Type": "4-ROOM",
        "Model": "NEW GENERATION",
        "Size": "98",
        "PSF": "380",
        "Price": "400,000",
        "Date": "10-Mar-21",
        "PSM": "4,090"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 212 TAMPINES STREET 23",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "105",
        "PSF": "398",
        "Price": "450,000",
        "Date": "10-Mar-21",
        "PSM": "4,284"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 424 TAMPINES STREET 41",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "411",
        "Price": "460,000",
        "Date": "10-Mar-21",
        "PSM": "4,424"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 498B TAMPINES STREET 45",
        "Type": "4-ROOM",
        "Model": "PREMIUM APARTMENT",
        "Size": "106",
        "PSF": "395",
        "Price": "450,000",
        "Date": "10-Mar-21",
        "PSM": "4,252"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 498J TAMPINES STREET 45",
        "Type": "4-ROOM",
        "Model": "PREMIUM APARTMENT",
        "Size": "106",
        "PSF": "404",
        "Price": "460,000",
        "Date": "10-Mar-21",
        "PSM": "4,349"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 719 TAMPINES STREET 72",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "100",
        "PSF": "418",
        "Price": "450,000",
        "Date": "10-Mar-21",
        "PSM": "4,499"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 866 TAMPINES STREET 83",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "106",
        "PSF": "404",
        "Price": "460,000",
        "Date": "10-Mar-21",
        "PSM": "4,349"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 910 TAMPINES STREET 91",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "389",
        "Price": "435,000",
        "Date": "10-Mar-21",
        "PSM": "4,187"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 935 TAMPINES STREET 91",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "106",
        "PSF": "382",
        "Price": "435,888",
        "Date": "10-Mar-21",
        "PSM": "4,112"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 137 SIMEI STREET 1",
        "Type": "4-ROOM",
        "Model": "SIMPLIFIED",
        "Size": "84",
        "PSF": "431",
        "Price": "390,000",
        "Date": "8-Mar-21",
        "PSM": "4,639"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 519B TAMPINES CENTRAL 8",
        "Type": "4-ROOM",
        "Model": "DBSS",
        "Size": "84",
        "PSF": "697",
        "Price": "630,000",
        "Date": "8-Mar-21",
        "PSM": "7,503"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 162 SIMEI ROAD",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "422",
        "Price": "472,000",
        "Date": "5-Mar-21",
        "PSM": "4,542"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 123 SIMEI STREET 1",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "411",
        "Price": "460,000",
        "Date": "5-Mar-21",
        "PSM": "4,424"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 805 TAMPINES AVENUE 4",
        "Type": "4-ROOM",
        "Model": "NEW GENERATION",
        "Size": "92",
        "PSF": "465",
        "Price": "460,000",
        "Date": "5-Mar-21",
        "PSM": "5,005"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 868B TAMPINES AVENUE 8",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "92",
        "PSF": "525",
        "Price": "520,000",
        "Date": "5-Mar-21",
        "PSM": "5,651"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 275 TAMPINES STREET 22",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "103",
        "PSF": "366",
        "Price": "405,000",
        "Date": "5-Mar-21",
        "PSM": "3,940"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 289 TAMPINES STREET 22",
        "Type": "4-ROOM",
        "Model": "NEW GENERATION",
        "Size": "98",
        "PSF": "380",
        "Price": "400,000",
        "Date": "5-Mar-21",
        "PSM": "4,090"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 322 TAMPINES STREET 33",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "107",
        "PSF": "369",
        "Price": "425,000",
        "Date": "5-Mar-21",
        "PSM": "3,972"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 713 TAMPINES STREET 71",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "100",
        "PSF": "423",
        "Price": "455,000",
        "Date": "5-Mar-21",
        "PSM": "4,553"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 842 TAMPINES STREET 82",
        "Type": "4-ROOM",
        "Model": "SIMPLIFIED",
        "Size": "84",
        "PSF": "442",
        "Price": "400,000",
        "Date": "5-Mar-21",
        "PSM": "4,758"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 871C TAMPINES STREET 86",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "94",
        "PSF": "562",
        "Price": "568,000",
        "Date": "5-Mar-21",
        "PSM": "6,049"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 871C TAMPINES STREET 86",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "94",
        "PSF": "572",
        "Price": "578,000",
        "Date": "5-Mar-21",
        "PSM": "6,157"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 868B TAMPINES AVENUE 8",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "92",
        "PSF": "532",
        "Price": "526,888",
        "Date": "3-Mar-21",
        "PSM": "5,726"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 142 TAMPINES STREET 12",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "103",
        "PSF": "406",
        "Price": "450,000",
        "Date": "3-Mar-21",
        "PSM": "4,370"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 161 TAMPINES STREET 12",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "106",
        "PSF": "465",
        "Price": "530,000",
        "Date": "3-Mar-21",
        "PSM": "5,005"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 274 TAMPINES STREET 22",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "105",
        "PSF": "389",
        "Price": "440,000",
        "Date": "3-Mar-21",
        "PSM": "4,187"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 283 TAMPINES STREET 22",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "103",
        "PSF": "370",
        "Price": "410,000",
        "Date": "3-Mar-21",
        "PSM": "3,983"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 229 TAMPINES STREET 23",
        "Type": "4-ROOM",
        "Model": "NEW GENERATION",
        "Size": "99",
        "PSF": "411",
        "Price": "438,000",
        "Date": "3-Mar-21",
        "PSM": "4,424"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 463 TAMPINES STREET 44",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "108",
        "PSF": "394",
        "Price": "458,000",
        "Date": "3-Mar-21",
        "PSM": "4,241"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 889A TAMPINES STREET 81",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "389",
        "Price": "435,000",
        "Date": "3-Mar-21",
        "PSM": "4,187"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 868B TAMPINES AVENUE 8",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "92",
        "PSF": "525",
        "Price": "520,000",
        "Date": "26-Feb-21",
        "PSM": "5,651"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 846 TAMPINES STREET 82",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "103",
        "PSF": "397",
        "Price": "440,000",
        "Date": "26-Feb-21",
        "PSM": "4,273"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 843 TAMPINES STREET 83",
        "Type": "4-ROOM",
        "Model": "SIMPLIFIED",
        "Size": "84",
        "PSF": "462",
        "Price": "418,000",
        "Date": "26-Feb-21",
        "PSM": "4,973"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 902 TAMPINES AVENUE 4",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "429",
        "Price": "480,000",
        "Date": "24-Feb-21",
        "PSM": "4,618"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 203 TAMPINES STREET 21",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "108",
        "PSF": "349",
        "Price": "405,000",
        "Date": "24-Feb-21",
        "PSM": "3,757"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 320 TAMPINES STREET 33",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "112",
        "PSF": "373",
        "Price": "450,000",
        "Date": "24-Feb-21",
        "PSM": "4,015"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 350 TAMPINES STREET 33",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "371",
        "Price": "415,000",
        "Date": "24-Feb-21",
        "PSM": "3,993"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 487B TAMPINES STREET 45",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "105",
        "PSF": "373",
        "Price": "422,000",
        "Date": "24-Feb-21",
        "PSM": "4,015"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 497C TAMPINES STREET 45",
        "Type": "4-ROOM",
        "Model": "PREMIUM APARTMENT",
        "Size": "107",
        "PSF": "365",
        "Price": "420,000",
        "Date": "24-Feb-21",
        "PSM": "3,929"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 871B TAMPINES STREET 86",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "93",
        "PSF": "564",
        "Price": "565,000",
        "Date": "24-Feb-21",
        "PSM": "6,071"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 167B SIMEI LANE",
        "Type": "4-ROOM",
        "Model": "DBSS",
        "Size": "94",
        "PSF": "673",
        "Price": "680,000",
        "Date": "22-Feb-21",
        "PSM": "7,244"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 902 TAMPINES AVENUE 4",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "105",
        "PSF": "432",
        "Price": "488,000",
        "Date": "22-Feb-21",
        "PSM": "4,650"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 485B TAMPINES AVENUE 9",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "349",
        "Price": "390,000",
        "Date": "22-Feb-21",
        "PSM": "3,757"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 103 TAMPINES STREET 11",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "117",
        "PSF": "346",
        "Price": "435,000",
        "Date": "22-Feb-21",
        "PSM": "3,724"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 870 TAMPINES STREET 83",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "395",
        "Price": "442,000",
        "Date": "22-Feb-21",
        "PSM": "4,252"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 109 SIMEI STREET 1",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "447",
        "Price": "500,000",
        "Date": "19-Feb-21",
        "PSM": "4,812"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 522A TAMPINES CENTRAL 7",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "93",
        "PSF": "606",
        "Price": "607,000",
        "Date": "19-Feb-21",
        "PSM": "6,523"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 144 TAMPINES STREET 12",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "106",
        "PSF": "399",
        "Price": "455,000",
        "Date": "19-Feb-21",
        "PSM": "4,295"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 226 TAMPINES STREET 23",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "105",
        "PSF": "381",
        "Price": "430,000",
        "Date": "19-Feb-21",
        "PSM": "4,101"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 438 TAMPINES STREET 43",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "357",
        "Price": "400,000",
        "Date": "19-Feb-21",
        "PSM": "3,843"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 489A TAMPINES STREET 45",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "362",
        "Price": "405,000",
        "Date": "19-Feb-21",
        "PSM": "3,897"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 704 TAMPINES STREET 71",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "99",
        "PSF": "432",
        "Price": "460,000",
        "Date": "19-Feb-21",
        "PSM": "4,650"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 867 TAMPINES STREET 83",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "365",
        "Price": "408,000",
        "Date": "19-Feb-21",
        "PSM": "3,929"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 867 TAMPINES STREET 83",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "371",
        "Price": "415,000",
        "Date": "19-Feb-21",
        "PSM": "3,993"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 934 TAMPINES STREET 91",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "395",
        "Price": "442,000",
        "Date": "19-Feb-21",
        "PSM": "4,252"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 890B TAMPINES AVENUE 1",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "93",
        "PSF": "524",
        "Price": "525,000",
        "Date": "17-Feb-21",
        "PSM": "5,640"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 266 TAMPINES STREET 21",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "105",
        "PSF": "396",
        "Price": "448,000",
        "Date": "17-Feb-21",
        "PSM": "4,263"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 282 TAMPINES STREET 22",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "353",
        "Price": "395,000",
        "Date": "17-Feb-21",
        "PSM": "3,800"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 224 TAMPINES STREET 23",
        "Type": "4-ROOM",
        "Model": "NEW GENERATION",
        "Size": "96",
        "PSF": "393",
        "Price": "406,000",
        "Date": "17-Feb-21",
        "PSM": "4,230"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 712 TAMPINES STREET 71",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "99",
        "PSF": "394",
        "Price": "420,000",
        "Date": "17-Feb-21",
        "PSM": "4,241"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 814 TAMPINES STREET 81",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "397",
        "Price": "443,888",
        "Date": "17-Feb-21",
        "PSM": "4,273"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 870A TAMPINES STREET 86",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "94",
        "PSF": "534",
        "Price": "540,000",
        "Date": "17-Feb-21",
        "PSM": "5,748"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 871B TAMPINES STREET 86",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "94",
        "PSF": "545",
        "Price": "551,000",
        "Date": "17-Feb-21",
        "PSM": "5,866"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 924 TAMPINES STREET 91",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "429",
        "Price": "480,000",
        "Date": "17-Feb-21",
        "PSM": "4,618"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 902 TAMPINES AVENUE 4",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "105",
        "PSF": "401",
        "Price": "452,888",
        "Date": "15-Feb-21",
        "PSM": "4,316"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 908 TAMPINES AVENUE 4",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "362",
        "Price": "405,000",
        "Date": "15-Feb-21",
        "PSM": "3,897"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 140 TAMPINES STREET 12",
        "Type": "4-ROOM",
        "Model": "NEW GENERATION",
        "Size": "91",
        "PSF": "409",
        "Price": "400,000",
        "Date": "15-Feb-21",
        "PSM": "4,402"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 283 TAMPINES STREET 22",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "103",
        "PSF": "388",
        "Price": "430,000",
        "Date": "15-Feb-21",
        "PSM": "4,176"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 890B TAMPINES AVENUE 1",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "93",
        "PSF": "554",
        "Price": "555,000",
        "Date": "12-Feb-21",
        "PSM": "5,963"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 902 TAMPINES AVENUE 4",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "411",
        "Price": "460,000",
        "Date": "12-Feb-21",
        "PSM": "4,424"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 869B TAMPINES AVENUE 8",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "92",
        "PSF": "513",
        "Price": "508,000",
        "Date": "12-Feb-21",
        "PSM": "5,522"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 140 TAMPINES STREET 12",
        "Type": "4-ROOM",
        "Model": "NEW GENERATION",
        "Size": "96",
        "PSF": "407",
        "Price": "420,000",
        "Date": "12-Feb-21",
        "PSM": "4,381"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 259 TAMPINES STREET 21",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "109",
        "PSF": "405",
        "Price": "475,000",
        "Date": "12-Feb-21",
        "PSM": "4,359"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 487B TAMPINES STREET 45",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "113",
        "PSF": "405",
        "Price": "492,000",
        "Date": "12-Feb-21",
        "PSM": "4,359"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 836 TAMPINES STREET 82",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "105",
        "PSF": "434",
        "Price": "490,000",
        "Date": "12-Feb-21",
        "PSM": "4,672"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 870A TAMPINES STREET 86",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "93",
        "PSF": "527",
        "Price": "528,000",
        "Date": "12-Feb-21",
        "PSM": "5,673"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 890B TAMPINES AVENUE 1",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "93",
        "PSF": "589",
        "Price": "590,000",
        "Date": "10-Feb-21",
        "PSM": "6,340"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 109 TAMPINES STREET 11",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "103",
        "PSF": "397",
        "Price": "440,000",
        "Date": "10-Feb-21",
        "PSM": "4,273"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 116 SIMEI STREET 1",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "429",
        "Price": "480,000",
        "Date": "5-Feb-21",
        "PSM": "4,618"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 131 SIMEI STREET 1",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "404",
        "Price": "451,888",
        "Date": "5-Feb-21",
        "PSM": "4,349"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 139 SIMEI STREET 1",
        "Type": "4-ROOM",
        "Model": "SIMPLIFIED",
        "Size": "88",
        "PSF": "422",
        "Price": "400,000",
        "Date": "5-Feb-21",
        "PSM": "4,542"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 523D TAMPINES CENTRAL 7",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "93",
        "PSF": "582",
        "Price": "582,500",
        "Date": "5-Feb-21",
        "PSM": "6,265"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 215 TAMPINES STREET 23",
        "Type": "4-ROOM",
        "Model": "NEW GENERATION",
        "Size": "96",
        "PSF": "405",
        "Price": "418,000",
        "Date": "5-Feb-21",
        "PSM": "4,359"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 709 TAMPINES STREET 71",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "99",
        "PSF": "399",
        "Price": "425,000",
        "Date": "5-Feb-21",
        "PSM": "4,295"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 885 TAMPINES STREET 83",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "103",
        "PSF": "379",
        "Price": "420,088",
        "Date": "5-Feb-21",
        "PSM": "4,080"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 523A TAMPINES CENTRAL 7",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "93",
        "PSF": "589",
        "Price": "590,000",
        "Date": "3-Feb-21",
        "PSM": "6,340"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 251 TAMPINES STREET 21",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "103",
        "PSF": "433",
        "Price": "480,000",
        "Date": "3-Feb-21",
        "PSM": "4,661"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 887 TAMPINES STREET 81",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "108",
        "PSF": "353",
        "Price": "410,000",
        "Date": "3-Feb-21",
        "PSM": "3,800"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 883 TAMPINES STREET 84",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "389",
        "Price": "435,000",
        "Date": "3-Feb-21",
        "PSM": "4,187"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 223 SIMEI STREET 4",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "416",
        "Price": "465,000",
        "Date": "1-Feb-21",
        "PSM": "4,478"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 465 TAMPINES STREET 44",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "105",
        "PSF": "425",
        "Price": "480,000",
        "Date": "1-Feb-21",
        "PSM": "4,575"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 145 TAMPINES STREET 12",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "103",
        "PSF": "440",
        "Price": "488,000",
        "Date": "29-Jan-21",
        "PSM": "4,736"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 359 TAMPINES STREET 34",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "107",
        "PSF": "339",
        "Price": "390,000",
        "Date": "29-Jan-21",
        "PSM": "3,649"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 733 TAMPINES STREET 71",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "103",
        "PSF": "415",
        "Price": "460,000",
        "Date": "29-Jan-21",
        "PSM": "4,467"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 930 TAMPINES STREET 91",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "402",
        "Price": "450,000",
        "Date": "29-Jan-21",
        "PSM": "4,327"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 140 TAMPINES STREET 12",
        "Type": "4-ROOM",
        "Model": "NEW GENERATION",
        "Size": "91",
        "PSF": "383",
        "Price": "375,000",
        "Date": "27-Jan-21",
        "PSM": "4,123"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 280 TAMPINES STREET 22",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "103",
        "PSF": "343",
        "Price": "380,000",
        "Date": "27-Jan-21",
        "PSM": "3,692"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 707 TAMPINES STREET 71",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "100",
        "PSF": "404",
        "Price": "435,000",
        "Date": "27-Jan-21",
        "PSM": "4,349"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 817 TAMPINES STREET 81",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "108",
        "PSF": "368",
        "Price": "428,000",
        "Date": "27-Jan-21",
        "PSM": "3,961"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 896 TAMPINES STREET 81",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "409",
        "Price": "458,000",
        "Date": "27-Jan-21",
        "PSM": "4,402"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 870A TAMPINES STREET 86",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "94",
        "PSF": "544",
        "Price": "550,000",
        "Date": "27-Jan-21",
        "PSM": "5,856"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 934 TAMPINES STREET 91",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "107",
        "PSF": "417",
        "Price": "480,000",
        "Date": "25-Jan-21",
        "PSM": "4,489"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 162 SIMEI ROAD",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "393",
        "Price": "440,000",
        "Date": "22-Jan-21",
        "PSM": "4,230"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 879 TAMPINES AVENUE 8",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "103",
        "PSF": "393",
        "Price": "435,000",
        "Date": "22-Jan-21",
        "PSM": "4,230"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 891A TAMPINES AVENUE 8",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "101",
        "PSF": "423",
        "Price": "460,000",
        "Date": "22-Jan-21",
        "PSM": "4,553"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 238 TAMPINES STREET 21",
        "Type": "4-ROOM",
        "Model": "NEW GENERATION",
        "Size": "91",
        "PSF": "460",
        "Price": "450,000",
        "Date": "22-Jan-21",
        "PSM": "4,951"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 247 TAMPINES STREET 21",
        "Type": "4-ROOM",
        "Model": "NEW GENERATION",
        "Size": "91",
        "PSF": "479",
        "Price": "468,888",
        "Date": "22-Jan-21",
        "PSM": "5,156"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 338 TAMPINES STREET 33",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "106",
        "PSF": "362",
        "Price": "412,888",
        "Date": "22-Jan-21",
        "PSM": "3,897"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 409 TAMPINES STREET 41",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "463",
        "Price": "518,000",
        "Date": "22-Jan-21",
        "PSM": "4,984"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 498B TAMPINES STREET 45",
        "Type": "4-ROOM",
        "Model": "PREMIUM APARTMENT",
        "Size": "106",
        "PSF": "395",
        "Price": "450,000",
        "Date": "22-Jan-21",
        "PSM": "4,252"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 850 TAMPINES STREET 82",
        "Type": "4-ROOM",
        "Model": "SIMPLIFIED",
        "Size": "89",
        "PSF": "428",
        "Price": "410,000",
        "Date": "20-Jan-21",
        "PSM": "4,607"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 871B TAMPINES STREET 86",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "94",
        "PSF": "540",
        "Price": "546,000",
        "Date": "20-Jan-21",
        "PSM": "5,813"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 861 TAMPINES AVENUE 5",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "436",
        "Price": "488,000",
        "Date": "18-Jan-21",
        "PSM": "4,693"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 354 TAMPINES STREET 33",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "109",
        "PSF": "375",
        "Price": "440,000",
        "Date": "18-Jan-21",
        "PSM": "4,037"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 735 TAMPINES STREET 72",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "102",
        "PSF": "397",
        "Price": "435,000",
        "Date": "18-Jan-21",
        "PSM": "4,273"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 883 TAMPINES STREET 84",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "429",
        "Price": "480,000",
        "Date": "18-Jan-21",
        "PSM": "4,618"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 933 TAMPINES STREET 91",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "375",
        "Price": "420,000",
        "Date": "18-Jan-21",
        "PSM": "4,037"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 811 TAMPINES AVENUE 4",
        "Type": "4-ROOM",
        "Model": "NEW GENERATION",
        "Size": "91",
        "PSF": "427",
        "Price": "418,000",
        "Date": "15-Jan-21",
        "PSM": "4,596"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 945 TAMPINES AVENUE 4",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "103",
        "PSF": "422",
        "Price": "468,000",
        "Date": "15-Jan-21",
        "PSM": "4,542"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 522A TAMPINES CENTRAL 7",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "93",
        "PSF": "544",
        "Price": "545,000",
        "Date": "15-Jan-21",
        "PSM": "5,856"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 110 TAMPINES STREET 11",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "103",
        "PSF": "379",
        "Price": "420,000",
        "Date": "15-Jan-21",
        "PSM": "4,080"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 267 TAMPINES STREET 21",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "366",
        "Price": "410,000",
        "Date": "15-Jan-21",
        "PSM": "3,940"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 355 TAMPINES STREET 33",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "107",
        "PSF": "361",
        "Price": "415,000",
        "Date": "15-Jan-21",
        "PSM": "3,886"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 721 TAMPINES STREET 71",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "99",
        "PSF": "415",
        "Price": "442,000",
        "Date": "15-Jan-21",
        "PSM": "4,467"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 728 TAMPINES STREET 71",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "384",
        "Price": "430,000",
        "Date": "15-Jan-21",
        "PSM": "4,133"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 847 TAMPINES STREET 83",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "108",
        "PSF": "400",
        "Price": "465,000",
        "Date": "15-Jan-21",
        "PSM": "4,306"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 870A TAMPINES STREET 86",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "94",
        "PSF": "524",
        "Price": "530,000",
        "Date": "15-Jan-21",
        "PSM": "5,640"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 222 SIMEI STREET 4",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "429",
        "Price": "480,000",
        "Date": "13-Jan-21",
        "PSM": "4,618"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 145 TAMPINES STREET 12",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "366",
        "Price": "410,000",
        "Date": "13-Jan-21",
        "PSM": "3,940"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 150 TAMPINES STREET 12",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "112",
        "PSF": "456",
        "Price": "550,000",
        "Date": "13-Jan-21",
        "PSM": "4,908"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 301 TAMPINES STREET 32",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "107",
        "PSF": "378",
        "Price": "435,000",
        "Date": "13-Jan-21",
        "PSM": "4,069"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 384 TAMPINES STREET 32",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "400",
        "Price": "448,000",
        "Date": "13-Jan-21",
        "PSM": "4,306"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 458 TAMPINES STREET 42",
        "Type": "4-ROOM",
        "Model": "SIMPLIFIED",
        "Size": "84",
        "PSF": "427",
        "Price": "386,000",
        "Date": "13-Jan-21",
        "PSM": "4,596"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 497C TAMPINES STREET 45",
        "Type": "4-ROOM",
        "Model": "PREMIUM APARTMENT",
        "Size": "102",
        "PSF": "383",
        "Price": "420,000",
        "Date": "13-Jan-21",
        "PSM": "4,123"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 497C TAMPINES STREET 45",
        "Type": "4-ROOM",
        "Model": "PREMIUM APARTMENT",
        "Size": "102",
        "PSF": "406",
        "Price": "445,000",
        "Date": "13-Jan-21",
        "PSM": "4,370"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 871 TAMPINES STREET 84",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "398",
        "Price": "445,000",
        "Date": "13-Jan-21",
        "PSM": "4,284"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 928 TAMPINES STREET 91",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "102",
        "PSF": "381",
        "Price": "418,000",
        "Date": "13-Jan-21",
        "PSM": "4,101"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 353 TAMPINES STREET 33",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "107",
        "PSF": "374",
        "Price": "430,000",
        "Date": "11-Jan-21",
        "PSM": "4,026"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 487B TAMPINES STREET 45",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "105",
        "PSF": "336",
        "Price": "380,000",
        "Date": "11-Jan-21",
        "PSM": "3,617"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 733 TAMPINES STREET 71",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "359",
        "Price": "402,000",
        "Date": "11-Jan-21",
        "PSM": "3,864"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 874 TAMPINES STREET 84",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "384",
        "Price": "430,000",
        "Date": "11-Jan-21",
        "PSM": "4,133"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 493B TAMPINES AVENUE 9",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "353",
        "Price": "395,000",
        "Date": "8-Jan-21",
        "PSM": "3,800"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 276 TAMPINES STREET 22",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "341",
        "Price": "382,000",
        "Date": "8-Jan-21",
        "PSM": "3,671"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 217 TAMPINES STREET 23",
        "Type": "4-ROOM",
        "Model": "NEW GENERATION",
        "Size": "93",
        "PSF": "393",
        "Price": "393,000",
        "Date": "8-Jan-21",
        "PSM": "4,230"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 363 TAMPINES STREET 34",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "107",
        "PSF": "365",
        "Price": "420,000",
        "Date": "8-Jan-21",
        "PSM": "3,929"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 421 TAMPINES STREET 41",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "105",
        "PSF": "425",
        "Price": "480,000",
        "Date": "8-Jan-21",
        "PSM": "4,575"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 487B TAMPINES STREET 45",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "105",
        "PSF": "381",
        "Price": "430,000",
        "Date": "8-Jan-21",
        "PSM": "4,101"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 838 TAMPINES STREET 82",
        "Type": "4-ROOM",
        "Model": "SIMPLIFIED",
        "Size": "84",
        "PSF": "442",
        "Price": "400,000",
        "Date": "8-Jan-21",
        "PSM": "4,758"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 869 TAMPINES STREET 83",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "380",
        "Price": "425,000",
        "Date": "8-Jan-21",
        "PSM": "4,090"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 868B TAMPINES AVENUE 8",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "92",
        "PSF": "475",
        "Price": "470,000",
        "Date": "6-Jan-21",
        "PSM": "5,113"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 144 TAMPINES STREET 12",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "106",
        "PSF": "404",
        "Price": "460,000",
        "Date": "6-Jan-21",
        "PSM": "4,349"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 353 TAMPINES STREET 33",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "107",
        "PSF": "339",
        "Price": "390,000",
        "Date": "6-Jan-21",
        "PSM": "3,649"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 487B TAMPINES STREET 45",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "105",
        "PSF": "372",
        "Price": "420,000",
        "Date": "6-Jan-21",
        "PSM": "4,004"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 871A TAMPINES STREET 84",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "402",
        "Price": "450,000",
        "Date": "6-Jan-21",
        "PSM": "4,327"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 929 TAMPINES STREET 91",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "366",
        "Price": "410,000",
        "Date": "6-Jan-21",
        "PSM": "3,940"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 909 TAMPINES AVENUE 4",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "105",
        "PSF": "397",
        "Price": "448,888",
        "Date": "1-Jan-21",
        "PSM": "4,273"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 845 TAMPINES STREET 83",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "366",
        "Price": "410,000",
        "Date": "1-Jan-21",
        "PSM": "3,940"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 870A TAMPINES STREET 86",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "94",
        "PSF": "564",
        "Price": "570,000",
        "Date": "1-Jan-21",
        "PSM": "6,071"
    }
];
export const fiveRm = [
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 153 SIMEI STREET 1",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "122",
        "PSF": "453",
        "Price": "595,000",
        "Date": "19-Mar-21",
        "PSM": "4,876"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 515C TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "DBSS",
        "Size": "109",
        "PSF": "630",
        "Price": "738,888",
        "Date": "17-Mar-21",
        "PSM": "6,781"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 518C TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "DBSS",
        "Size": "109",
        "PSF": "612",
        "Price": "718,000",
        "Date": "17-Mar-21",
        "PSM": "6,588"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 731 TAMPINES STREET 71",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "123",
        "PSF": "393",
        "Price": "520,000",
        "Date": "17-Mar-21",
        "PSM": "4,230"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 163 SIMEI ROAD",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "122",
        "PSF": "407",
        "Price": "535,000",
        "Date": "15-Mar-21",
        "PSM": "4,381"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 112 SIMEI STREET 1",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "396",
        "Price": "515,000",
        "Date": "12-Mar-21",
        "PSM": "4,263"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 496D TAMPINES AVENUE 9",
        "Type": "5-ROOM",
        "Model": "MODEL A",
        "Size": "137",
        "PSF": "390",
        "Price": "575,000",
        "Date": "12-Mar-21",
        "PSM": "4,198"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 257 TAMPINES STREET 21",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "432",
        "Price": "562,500",
        "Date": "12-Mar-21",
        "PSM": "4,650"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 343 TAMPINES STREET 33",
        "Type": "5-ROOM",
        "Model": "MODEL A",
        "Size": "135",
        "PSF": "358",
        "Price": "520,888",
        "Date": "12-Mar-21",
        "PSM": "3,854"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 861A TAMPINES AVENUE 5",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "127",
        "PSF": "417",
        "Price": "570,000",
        "Date": "10-Mar-21",
        "PSM": "4,489"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 522C TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "113",
        "PSF": "551",
        "Price": "670,000",
        "Date": "10-Mar-21",
        "PSM": "5,931"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 232 TAMPINES STREET 21",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "384",
        "Price": "500,000",
        "Date": "10-Mar-21",
        "PSM": "4,133"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 338 TAMPINES STREET 33",
        "Type": "5-ROOM",
        "Model": "MODEL A",
        "Size": "135",
        "PSF": "389",
        "Price": "565,000",
        "Date": "10-Mar-21",
        "PSM": "4,187"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 497H TAMPINES STREET 45",
        "Type": "5-ROOM",
        "Model": "PREMIUM APARTMENT",
        "Size": "131",
        "PSF": "383",
        "Price": "540,000",
        "Date": "10-Mar-21",
        "PSM": "4,123"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 391 TAMPINES AVENUE 7",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "125",
        "PSF": "409",
        "Price": "550,000",
        "Date": "8-Mar-21",
        "PSM": "4,402"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 523A TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "113",
        "PSF": "603",
        "Price": "733,000",
        "Date": "8-Mar-21",
        "PSM": "6,491"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 711 TAMPINES STREET 71",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "119",
        "PSF": "410",
        "Price": "525,000",
        "Date": "8-Mar-21",
        "PSM": "4,413"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 897 TAMPINES STREET 81",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "122",
        "PSF": "443",
        "Price": "581,888",
        "Date": "8-Mar-21",
        "PSM": "4,768"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 871B TAMPINES STREET 86",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "113",
        "PSF": "566",
        "Price": "688,000",
        "Date": "8-Mar-21",
        "PSM": "6,092"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 524A TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "113",
        "PSF": "579",
        "Price": "703,888",
        "Date": "5-Mar-21",
        "PSM": "6,232"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 384 TAMPINES STREET 32",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "125",
        "PSF": "405",
        "Price": "545,000",
        "Date": "5-Mar-21",
        "PSM": "4,359"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 341 TAMPINES STREET 33",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "127",
        "PSF": "386",
        "Price": "528,000",
        "Date": "5-Mar-21",
        "PSM": "4,155"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 347 TAMPINES STREET 33",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "377",
        "Price": "491,000",
        "Date": "5-Mar-21",
        "PSM": "4,058"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 425 TAMPINES STREET 41",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "122",
        "PSF": "404",
        "Price": "530,000",
        "Date": "5-Mar-21",
        "PSM": "4,349"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 871C TAMPINES STREET 86",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "114",
        "PSF": "512",
        "Price": "628,000",
        "Date": "5-Mar-21",
        "PSM": "5,511"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 168B SIMEI LANE",
        "Type": "5-ROOM",
        "Model": "DBSS",
        "Size": "107",
        "PSF": "693",
        "Price": "798,000",
        "Date": "3-Mar-21",
        "PSM": "7,459"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 168D SIMEI LANE",
        "Type": "5-ROOM",
        "Model": "DBSS",
        "Size": "107",
        "PSF": "626",
        "Price": "720,000",
        "Date": "3-Mar-21",
        "PSM": "6,738"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 494B TAMPINES AVENUE 9",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "120",
        "PSF": "387",
        "Price": "500,000",
        "Date": "3-Mar-21",
        "PSM": "4,166"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 515B TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "DBSS",
        "Size": "113",
        "PSF": "678",
        "Price": "825,000",
        "Date": "3-Mar-21",
        "PSM": "7,298"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 498F TAMPINES STREET 45",
        "Type": "5-ROOM",
        "Model": "PREMIUM APARTMENT",
        "Size": "124",
        "PSF": "392",
        "Price": "523,000",
        "Date": "3-Mar-21",
        "PSM": "4,219"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 842H TAMPINES STREET 82",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "128",
        "PSF": "421",
        "Price": "580,000",
        "Date": "3-Mar-21",
        "PSM": "4,532"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 523A TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "113",
        "PSF": "613",
        "Price": "745,000",
        "Date": "26-Feb-21",
        "PSM": "6,598"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 490B TAMPINES STREET 45",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "128",
        "PSF": "370",
        "Price": "510,000",
        "Date": "26-Feb-21",
        "PSM": "3,983"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 842H TAMPINES STREET 82",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "124",
        "PSF": "478",
        "Price": "638,000",
        "Date": "26-Feb-21",
        "PSM": "5,145"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 120 SIMEI STREET 1",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "128",
        "PSF": "399",
        "Price": "550,000",
        "Date": "24-Feb-21",
        "PSM": "4,295"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 131 SIMEI STREET 1",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "122",
        "PSF": "431",
        "Price": "565,888",
        "Date": "24-Feb-21",
        "PSM": "4,639"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 392 TAMPINES AVENUE 7",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "419",
        "Price": "545,000",
        "Date": "24-Feb-21",
        "PSM": "4,510"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 524A TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "113",
        "PSF": "609",
        "Price": "740,000",
        "Date": "24-Feb-21",
        "PSM": "6,555"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 301 TAMPINES STREET 32",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "125",
        "PSF": "405",
        "Price": "545,000",
        "Date": "24-Feb-21",
        "PSM": "4,359"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 306 TAMPINES STREET 32",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "125",
        "PSF": "409",
        "Price": "550,000",
        "Date": "24-Feb-21",
        "PSM": "4,402"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 704 TAMPINES STREET 71",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "119",
        "PSF": "457",
        "Price": "585,000",
        "Date": "22-Feb-21",
        "PSM": "4,919"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 167C SIMEI LANE",
        "Type": "5-ROOM",
        "Model": "DBSS",
        "Size": "107",
        "PSF": "685",
        "Price": "788,888",
        "Date": "19-Feb-21",
        "PSM": "7,373"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 888A TAMPINES STREET 81",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "122",
        "PSF": "387",
        "Price": "508,000",
        "Date": "19-Feb-21",
        "PSM": "4,166"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 912 TAMPINES STREET 91",
        "Type": "5-ROOM",
        "Model": "MODEL A",
        "Size": "133",
        "PSF": "430",
        "Price": "615,000",
        "Date": "19-Feb-21",
        "PSM": "4,629"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 859 TAMPINES AVENUE 5",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "127",
        "PSF": "475",
        "Price": "650,000",
        "Date": "17-Feb-21",
        "PSM": "5,113"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 523D TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "113",
        "PSF": "514",
        "Price": "625,000",
        "Date": "17-Feb-21",
        "PSM": "5,533"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 523D TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "113",
        "PSF": "584",
        "Price": "710,000",
        "Date": "17-Feb-21",
        "PSM": "6,286"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 321 TAMPINES STREET 33",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "128",
        "PSF": "369",
        "Price": "508,000",
        "Date": "17-Feb-21",
        "PSM": "3,972"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 458 TAMPINES STREET 42",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "122",
        "PSF": "427",
        "Price": "560,000",
        "Date": "17-Feb-21",
        "PSM": "4,596"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 869 TAMPINES STREET 83",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "376",
        "Price": "490,000",
        "Date": "17-Feb-21",
        "PSM": "4,047"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 154 SIMEI STREET 1",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "122",
        "PSF": "409",
        "Price": "537,000",
        "Date": "15-Feb-21",
        "PSM": "4,402"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 146 SIMEI STREET 2",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "122",
        "PSF": "415",
        "Price": "545,000",
        "Date": "15-Feb-21",
        "PSM": "4,467"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 491D TAMPINES STREET 45",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "122",
        "PSF": "385",
        "Price": "505,000",
        "Date": "15-Feb-21",
        "PSM": "4,144"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 842E TAMPINES STREET 82",
        "Type": "5-ROOM",
        "Model": "MODEL A",
        "Size": "139",
        "PSF": "461",
        "Price": "690,000",
        "Date": "15-Feb-21",
        "PSM": "4,962"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 342 TAMPINES STREET 33",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "124",
        "PSF": "360",
        "Price": "480,000",
        "Date": "12-Feb-21",
        "PSM": "3,875"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 870A TAMPINES STREET 86",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "113",
        "PSF": "551",
        "Price": "670,000",
        "Date": "12-Feb-21",
        "PSM": "5,931"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 932 TAMPINES STREET 91",
        "Type": "5-ROOM",
        "Model": "MODEL A",
        "Size": "132",
        "PSF": "408",
        "Price": "580,000",
        "Date": "12-Feb-21",
        "PSM": "4,392"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 130 SIMEI STREET 1",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "421",
        "Price": "548,000",
        "Date": "10-Feb-21",
        "PSM": "4,532"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 254 SIMEI STREET 1",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "118",
        "PSF": "472",
        "Price": "600,000",
        "Date": "10-Feb-21",
        "PSM": "5,081"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 486A TAMPINES AVENUE 9",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "127",
        "PSF": "380",
        "Price": "520,000",
        "Date": "10-Feb-21",
        "PSM": "4,090"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 871B TAMPINES STREET 86",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "113",
        "PSF": "547",
        "Price": "665,000",
        "Date": "10-Feb-21",
        "PSM": "5,888"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 168D SIMEI LANE",
        "Type": "5-ROOM",
        "Model": "DBSS",
        "Size": "111",
        "PSF": "611",
        "Price": "730,000",
        "Date": "5-Feb-21",
        "PSM": "6,577"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 120 SIMEI STREET 1",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "128",
        "PSF": "421",
        "Price": "580,088",
        "Date": "5-Feb-21",
        "PSM": "4,532"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 496D TAMPINES AVENUE 9",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "126",
        "PSF": "361",
        "Price": "490,000",
        "Date": "3-Feb-21",
        "PSM": "3,886"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 496E TAMPINES AVENUE 9",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "124",
        "PSF": "416",
        "Price": "555,000",
        "Date": "3-Feb-21",
        "PSM": "4,478"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 519B TAMPINES CENTRAL 8",
        "Type": "5-ROOM",
        "Model": "DBSS",
        "Size": "109",
        "PSF": "736",
        "Price": "863,500",
        "Date": "3-Feb-21",
        "PSM": "7,922"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 138 SIMEI STREET 1",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "122",
        "PSF": "400",
        "Price": "525,000",
        "Date": "1-Feb-21",
        "PSM": "4,306"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 361 TAMPINES STREET 34",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "127",
        "PSF": "366",
        "Price": "500,000",
        "Date": "1-Feb-21",
        "PSM": "3,940"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 524A TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "113",
        "PSF": "596",
        "Price": "725,000",
        "Date": "29-Jan-21",
        "PSM": "6,415"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 230C TAMPINES STREET 24",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "127",
        "PSF": "418",
        "Price": "572,000",
        "Date": "29-Jan-21",
        "PSM": "4,499"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 713 TAMPINES STREET 71",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "119",
        "PSF": "391",
        "Price": "500,000",
        "Date": "29-Jan-21",
        "PSM": "4,209"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 522C TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "113",
        "PSF": "600",
        "Price": "730,000",
        "Date": "27-Jan-21",
        "PSM": "6,458"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 294 TAMPINES STREET 22",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "365",
        "Price": "475,000",
        "Date": "27-Jan-21",
        "PSM": "3,929"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 381 TAMPINES STREET 32",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "129",
        "PSF": "400",
        "Price": "555,000",
        "Date": "27-Jan-21",
        "PSM": "4,306"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 450D TAMPINES STREET 42",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "124",
        "PSF": "426",
        "Price": "568,000",
        "Date": "27-Jan-21",
        "PSM": "4,585"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 871 TAMPINES STREET 84",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "394",
        "Price": "513,000",
        "Date": "27-Jan-21",
        "PSM": "4,241"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 143 TAMPINES STREET 12",
        "Type": "5-ROOM",
        "Model": "MODEL A",
        "Size": "133",
        "PSF": "426",
        "Price": "610,000",
        "Date": "25-Jan-21",
        "PSM": "4,585"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 254 TAMPINES STREET 21",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "402",
        "Price": "523,000",
        "Date": "25-Jan-21",
        "PSM": "4,327"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 872 TAMPINES STREET 84",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "407",
        "Price": "530,000",
        "Date": "25-Jan-21",
        "PSM": "4,381"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 494B TAMPINES AVENUE 9",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "124",
        "PSF": "360",
        "Price": "480,000",
        "Date": "22-Jan-21",
        "PSM": "3,875"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 515A TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "DBSS",
        "Size": "109",
        "PSF": "642",
        "Price": "752,888",
        "Date": "22-Jan-21",
        "PSM": "6,910"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 518D TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "DBSS",
        "Size": "109",
        "PSF": "639",
        "Price": "750,000",
        "Date": "22-Jan-21",
        "PSM": "6,878"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 524A TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "113",
        "PSF": "498",
        "Price": "605,000",
        "Date": "22-Jan-21",
        "PSM": "5,360"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 489B TAMPINES STREET 45",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "126",
        "PSF": "332",
        "Price": "450,000",
        "Date": "22-Jan-21",
        "PSM": "3,574"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 491H TAMPINES STREET 45",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "124",
        "PSF": "412",
        "Price": "550,000",
        "Date": "22-Jan-21",
        "PSM": "4,435"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 723 TAMPINES STREET 72",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "119",
        "PSF": "434",
        "Price": "555,000",
        "Date": "22-Jan-21",
        "PSM": "4,672"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 141 SIMEI STREET 2",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "122",
        "PSF": "373",
        "Price": "490,000",
        "Date": "20-Jan-21",
        "PSM": "4,015"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 816 TAMPINES AVENUE 4",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "415",
        "Price": "540,000",
        "Date": "20-Jan-21",
        "PSM": "4,467"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 486C TAMPINES AVENUE 9",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "127",
        "PSF": "413",
        "Price": "565,000",
        "Date": "20-Jan-21",
        "PSM": "4,446"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 523D TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "113",
        "PSF": "555",
        "Price": "675,000",
        "Date": "20-Jan-21",
        "PSM": "5,974"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 365 TAMPINES STREET 34",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "413",
        "Price": "538,000",
        "Date": "20-Jan-21",
        "PSM": "4,446"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 704 TAMPINES STREET 71",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "120",
        "PSF": "402",
        "Price": "519,000",
        "Date": "20-Jan-21",
        "PSM": "4,327"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 518D TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "DBSS",
        "Size": "109",
        "PSF": "703",
        "Price": "825,000",
        "Date": "18-Jan-21",
        "PSM": "7,567"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 143 TAMPINES STREET 12",
        "Type": "5-ROOM",
        "Model": "MODEL A",
        "Size": "133",
        "PSF": "449",
        "Price": "642,888",
        "Date": "18-Jan-21",
        "PSM": "4,833"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 288 TAMPINES STREET 22",
        "Type": "5-ROOM",
        "Model": "MODEL A",
        "Size": "133",
        "PSF": "341",
        "Price": "488,000",
        "Date": "18-Jan-21",
        "PSM": "3,671"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 491F TAMPINES STREET 45",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "124",
        "PSF": "409",
        "Price": "545,000",
        "Date": "18-Jan-21",
        "PSM": "4,402"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 155 SIMEI ROAD",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "353",
        "Price": "460,000",
        "Date": "15-Jan-21",
        "PSM": "3,800"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 524A TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "113",
        "PSF": "555",
        "Price": "675,000",
        "Date": "15-Jan-21",
        "PSM": "5,974"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 260 TAMPINES STREET 21",
        "Type": "5-ROOM",
        "Model": "MODEL A",
        "Size": "133",
        "PSF": "367",
        "Price": "525,000",
        "Date": "15-Jan-21",
        "PSM": "3,950"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 268 TAMPINES STREET 21",
        "Type": "5-ROOM",
        "Model": "MODEL A",
        "Size": "133",
        "PSF": "405",
        "Price": "580,000",
        "Date": "15-Jan-21",
        "PSM": "4,359"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 321 TAMPINES STREET 33",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "128",
        "PSF": "363",
        "Price": "500,000",
        "Date": "15-Jan-21",
        "PSM": "3,907"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 708 TAMPINES STREET 71",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "119",
        "PSF": "428",
        "Price": "548,000",
        "Date": "15-Jan-21",
        "PSM": "4,607"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 869 TAMPINES STREET 83",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "413",
        "Price": "538,000",
        "Date": "15-Jan-21",
        "PSM": "4,446"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 122 SIMEI STREET 1",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "122",
        "PSF": "381",
        "Price": "500,000",
        "Date": "13-Jan-21",
        "PSM": "4,101"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 232 TAMPINES STREET 21",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "411",
        "Price": "535,000",
        "Date": "13-Jan-21",
        "PSM": "4,424"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 260 TAMPINES STREET 21",
        "Type": "5-ROOM",
        "Model": "MODEL A",
        "Size": "133",
        "PSF": "398",
        "Price": "570,000",
        "Date": "13-Jan-21",
        "PSM": "4,284"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 886 TAMPINES STREET 83",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "122",
        "PSF": "379",
        "Price": "498,000",
        "Date": "13-Jan-21",
        "PSM": "4,080"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 872 TAMPINES STREET 84",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "411",
        "Price": "535,000",
        "Date": "13-Jan-21",
        "PSM": "4,424"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 522B TAMPINES CENTRAL 7",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "113",
        "PSF": "580",
        "Price": "705,000",
        "Date": "11-Jan-21",
        "PSM": "6,243"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 230 TAMPINES STREET 24",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "413",
        "Price": "538,000",
        "Date": "11-Jan-21",
        "PSM": "4,446"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 497H TAMPINES STREET 45",
        "Type": "5-ROOM",
        "Model": "PREMIUM APARTMENT",
        "Size": "131",
        "PSF": "372",
        "Price": "525,000",
        "Date": "11-Jan-21",
        "PSM": "4,004"
    }
];
export const exe = [
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 941 TAMPINES AVENUE 5",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "146",
        "PSF": "482",
        "Price": "758,000",
        "Date": "19-Mar-21",
        "PSM": "5,188"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 857 TAMPINES STREET 83",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "146",
        "PSF": "490",
        "Price": "770,000",
        "Date": "12-Mar-21",
        "PSM": "5,274"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 495E TAMPINES STREET 43",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "147",
        "PSF": "401",
        "Price": "635,000",
        "Date": "10-Mar-21",
        "PSM": "4,316"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 867A TAMPINES STREET 83",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "146",
        "PSF": "452",
        "Price": "710,000",
        "Date": "5-Mar-21",
        "PSM": "4,865"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 738 TAMPINES STREET 72",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "141",
        "PSF": "435",
        "Price": "660,000",
        "Date": "5-Mar-21",
        "PSM": "4,682"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 428 TAMPINES STREET 41",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "148",
        "PSF": "401",
        "Price": "638,800",
        "Date": "5-Mar-21",
        "PSM": "4,316"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 856E TAMPINES STREET 82",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "147",
        "PSF": "540",
        "Price": "855,000",
        "Date": "3-Mar-21",
        "PSM": "5,813"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 863 TAMPINES STREET 83",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "154",
        "PSF": "477",
        "Price": "790,000",
        "Date": "26-Feb-21",
        "PSM": "5,134"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 477 TAMPINES STREET 43",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "159",
        "PSF": "431",
        "Price": "738,000",
        "Date": "26-Feb-21",
        "PSM": "4,639"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 495E TAMPINES STREET 43",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "146",
        "PSF": "388",
        "Price": "610,000",
        "Date": "24-Feb-21",
        "PSM": "4,176"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 148 TAMPINES AVENUE 5",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "150",
        "PSF": "462",
        "Price": "745,000",
        "Date": "24-Feb-21",
        "PSM": "4,973"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 892 TAMPINES AVENUE 8",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "142",
        "PSF": "491",
        "Price": "750,000",
        "Date": "22-Feb-21",
        "PSM": "5,285"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 335 TAMPINES STREET 32",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "147",
        "PSF": "424",
        "Price": "670,000",
        "Date": "22-Feb-21",
        "PSM": "4,564"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 428 TAMPINES STREET 41",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "148",
        "PSF": "392",
        "Price": "625,000",
        "Date": "22-Feb-21",
        "PSM": "4,219"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 867A TAMPINES STREET 83",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "146",
        "PSF": "420",
        "Price": "660,000",
        "Date": "19-Feb-21",
        "PSM": "4,521"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 495C TAMPINES STREET 43",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "147",
        "PSF": "386",
        "Price": "610,000",
        "Date": "19-Feb-21",
        "PSM": "4,155"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 874A TAMPINES STREET 84",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "146",
        "PSF": "407",
        "Price": "640,000",
        "Date": "17-Feb-21",
        "PSM": "4,381"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 856B TAMPINES STREET 82",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "145",
        "PSF": "532",
        "Price": "830,000",
        "Date": "17-Feb-21",
        "PSM": "5,726"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 474 TAMPINES STREET 43",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "153",
        "PSF": "448",
        "Price": "738,000",
        "Date": "17-Feb-21",
        "PSM": "4,822"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 311 TAMPINES STREET 33",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "147",
        "PSF": "427",
        "Price": "675,000",
        "Date": "17-Feb-21",
        "PSM": "4,596"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 230E TAMPINES STREET 24",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "150",
        "PSF": "415",
        "Price": "670,000",
        "Date": "17-Feb-21",
        "PSM": "4,467"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 245 TAMPINES STREET 21",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "150",
        "PSF": "463",
        "Price": "748,000",
        "Date": "17-Feb-21",
        "PSM": "4,984"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 497D TAMPINES STREET 45",
        "Type": "EXECUTIVE",
        "Model": "PREMIUM APARTMENT",
        "Size": "139",
        "PSF": "442",
        "Price": "660,888",
        "Date": "12-Feb-21",
        "PSM": "4,758"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 856F TAMPINES STREET 82",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "145",
        "PSF": "503",
        "Price": "785,000",
        "Date": "10-Feb-21",
        "PSM": "5,414"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 889 TAMPINES STREET 81",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "149",
        "PSF": "471",
        "Price": "755,000",
        "Date": "5-Feb-21",
        "PSM": "5,070"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 430 TAMPINES STREET 41",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "148",
        "PSF": "395",
        "Price": "630,000",
        "Date": "3-Feb-21",
        "PSM": "4,252"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 332 TAMPINES STREET 32",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "147",
        "PSF": "429",
        "Price": "678,000",
        "Date": "3-Feb-21",
        "PSM": "4,618"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 229 SIMEI STREET 4",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "146",
        "PSF": "444",
        "Price": "698,000",
        "Date": "1-Feb-21",
        "PSM": "4,779"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 309 TAMPINES STREET 32",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "143",
        "PSF": "417",
        "Price": "642,000",
        "Date": "29-Jan-21",
        "PSM": "4,489"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 889 TAMPINES STREET 81",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "146",
        "PSF": "417",
        "Price": "655,000",
        "Date": "27-Jan-21",
        "PSM": "4,489"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 428 TAMPINES STREET 41",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "148",
        "PSF": "389",
        "Price": "620,000",
        "Date": "27-Jan-21",
        "PSM": "4,187"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 333 TAMPINES STREET 32",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "149",
        "PSF": "434",
        "Price": "695,888",
        "Date": "27-Jan-21",
        "PSM": "4,672"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 329 TAMPINES STREET 32",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "148",
        "PSF": "408",
        "Price": "650,000",
        "Date": "27-Jan-21",
        "PSM": "4,392"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 893 TAMPINES AVENUE 8",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "144",
        "PSF": "444",
        "Price": "688,000",
        "Date": "22-Jan-21",
        "PSM": "4,779"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 893 TAMPINES AVENUE 8",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "137",
        "PSF": "465",
        "Price": "685,000",
        "Date": "22-Jan-21",
        "PSM": "5,005"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 146 TAMPINES AVENUE 5",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "148",
        "PSF": "446",
        "Price": "710,000",
        "Date": "22-Jan-21",
        "PSM": "4,801"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 151 TAMPINES STREET 12",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "150",
        "PSF": "496",
        "Price": "800,000",
        "Date": "20-Jan-21",
        "PSM": "5,339"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 856D TAMPINES STREET 82",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "146",
        "PSF": "514",
        "Price": "808,000",
        "Date": "13-Jan-21",
        "PSM": "5,533"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 856C TAMPINES STREET 82",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "154",
        "PSF": "462",
        "Price": "765,000",
        "Date": "13-Jan-21",
        "PSM": "4,973"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 324 TAMPINES STREET 32",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "148",
        "PSF": "405",
        "Price": "645,000",
        "Date": "11-Jan-21",
        "PSM": "4,359"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 867A TAMPINES STREET 83",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "146",
        "PSF": "438",
        "Price": "687,500",
        "Date": "11-Jan-21",
        "PSM": "4,715"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 124 TAMPINES STREET 11",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "152",
        "PSF": "397",
        "Price": "650,000",
        "Date": "8-Jan-21",
        "PSM": "4,273"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 148 TAMPINES AVENUE 5",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "142",
        "PSF": "468",
        "Price": "715,000",
        "Date": "8-Jan-21",
        "PSM": "5,038"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 147 SIMEI STREET 2",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "146",
        "PSF": "449",
        "Price": "705,000",
        "Date": "8-Jan-21",
        "PSM": "4,833"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 857 TAMPINES STREET 83",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "154",
        "PSF": "495",
        "Price": "820,000",
        "Date": "6-Jan-21",
        "PSM": "5,328"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 498E TAMPINES STREET 45",
        "Type": "EXECUTIVE",
        "Model": "PREMIUM APARTMENT",
        "Size": "143",
        "PSF": "415",
        "Price": "638,000",
        "Date": "6-Jan-21",
        "PSM": "4,467"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 495B TAMPINES STREET 43",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "147",
        "PSF": "386",
        "Price": "610,000",
        "Date": "6-Jan-21",
        "PSM": "4,155"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 429 TAMPINES STREET 41",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "152",
        "PSF": "382",
        "Price": "625,000",
        "Date": "6-Jan-21",
        "PSM": "4,112"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 332 TAMPINES STREET 32",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "149",
        "PSF": "424",
        "Price": "678,888",
        "Date": "6-Jan-21",
        "PSM": "4,564"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 156 TAMPINES STREET 12",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "150",
        "PSF": "496",
        "Price": "800,000",
        "Date": "6-Jan-21",
        "PSM": "5,339"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 858 TAMPINES AVENUE 5",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "146",
        "PSF": "489",
        "Price": "768,000",
        "Date": "6-Jan-21",
        "PSM": "5,264"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 333 TAMPINES STREET 32",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "147",
        "PSF": "424",
        "Price": "670,000",
        "Date": "1-Jan-21",
        "PSM": "4,564"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 428 TAMPINES STREET 41",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "154",
        "PSF": "397",
        "Price": "658,000",
        "Date": "30-Dec-20",
        "PSM": "4,273"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 313 TAMPINES STREET 33",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "145",
        "PSF": "410",
        "Price": "640,000",
        "Date": "30-Dec-20",
        "PSM": "4,413"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 124 TAMPINES STREET 11",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "148",
        "PSF": "414",
        "Price": "660,000",
        "Date": "30-Dec-20",
        "PSM": "4,456"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 497F TAMPINES STREET 45",
        "Type": "EXECUTIVE",
        "Model": "PREMIUM APARTMENT",
        "Size": "144",
        "PSF": "387",
        "Price": "600,000",
        "Date": "28-Dec-20",
        "PSM": "4,166"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 495B TAMPINES STREET 43",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "147",
        "PSF": "397",
        "Price": "628,000",
        "Date": "25-Dec-20",
        "PSM": "4,273"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 308 TAMPINES STREET 32",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "143",
        "PSF": "429",
        "Price": "660,000",
        "Date": "25-Dec-20",
        "PSM": "4,618"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 336 TAMPINES STREET 32",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "150",
        "PSF": "452",
        "Price": "730,000",
        "Date": "23-Dec-20",
        "PSM": "4,865"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 940 TAMPINES AVENUE 5",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "146",
        "PSF": "446",
        "Price": "700,000",
        "Date": "23-Dec-20",
        "PSM": "4,801"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 858 TAMPINES AVENUE 5",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "154",
        "PSF": "477",
        "Price": "790,000",
        "Date": "23-Dec-20",
        "PSM": "5,134"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 856B TAMPINES STREET 82",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "145",
        "PSF": "531",
        "Price": "828,000",
        "Date": "18-Dec-20",
        "PSM": "5,716"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 156 TAMPINES STREET 12",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "147",
        "PSF": "506",
        "Price": "800,000",
        "Date": "16-Dec-20",
        "PSM": "5,447"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 125 TAMPINES STREET 11",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "147",
        "PSF": "397",
        "Price": "628,000",
        "Date": "16-Dec-20",
        "PSM": "4,273"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 230J TAMPINES STREET 21",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "149",
        "PSF": "424",
        "Price": "680,000",
        "Date": "14-Dec-20",
        "PSM": "4,564"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 430 TAMPINES STREET 41",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "148",
        "PSF": "367",
        "Price": "585,000",
        "Date": "11-Dec-20",
        "PSM": "3,950"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 308 TAMPINES STREET 32",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "142",
        "PSF": "455",
        "Price": "695,000",
        "Date": "11-Dec-20",
        "PSM": "4,898"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 245 TAMPINES STREET 21",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "150",
        "PSF": "496",
        "Price": "800,000",
        "Date": "11-Dec-20",
        "PSM": "5,339"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 937 TAMPINES AVENUE 5",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "146",
        "PSF": "455",
        "Price": "715,000",
        "Date": "11-Dec-20",
        "PSM": "4,898"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 147 SIMEI STREET 2",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "146",
        "PSF": "455",
        "Price": "715,000",
        "Date": "9-Dec-20",
        "PSM": "4,898"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 476 TAMPINES STREET 44",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "159",
        "PSF": "415",
        "Price": "710,000",
        "Date": "4-Dec-20",
        "PSM": "4,467"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 431 TAMPINES STREET 41",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "148",
        "PSF": "386",
        "Price": "615,000",
        "Date": "4-Dec-20",
        "PSM": "4,155"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 860 TAMPINES AVENUE 5",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "146",
        "PSF": "490",
        "Price": "770,000",
        "Date": "4-Dec-20",
        "PSM": "5,274"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 942 TAMPINES AVENUE 5",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "154",
        "PSF": "468",
        "Price": "775,000",
        "Date": "2-Dec-20",
        "PSM": "5,038"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 895A TAMPINES STREET 81",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "146",
        "PSF": "414",
        "Price": "650,000",
        "Date": "2-Dec-20",
        "PSM": "4,456"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 856E TAMPINES STREET 82",
        "Type": "EXECUTIVE",
        "Model": "APARTMENT",
        "Size": "147",
        "PSF": "489",
        "Price": "773,000",
        "Date": "2-Dec-20",
        "PSM": "5,264"
    },
    {
        "Town": "TAMPINES",
        "StreetName": "BLK 894 TAMPINES STREET 81",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "149",
        "PSF": "414",
        "Price": "663,000",
        "Date": "1-Dec-20",
        "PSM": "4,456"
    }
];