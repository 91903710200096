import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import Logo from '../../../images/PropertyLawrence.png';

const Header = () => {
    return (
        <Navbar sticky='top' expand="lg" bg="white" variant="light" style={{ height: 70, width: '100%' }}>
            <Navbar.Brand href="home">
                <img style={{
                    marginLeft: 10,
                    width: 120,
                    objectFit:'fill',
                    }} src={Logo} alt="Lawrence">
                </img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav"
                style={{
                    // backgroundColor: (windowsize < 992 ? 'rgba(2, 2, 2, 0.61)' : null),
                    backgroundColor: 'white',
                    padding: 25, height: 70,
                    fontFamily: 'Barlow',
                    }}>
                {/* mr for left, mx for centre, ml for right */}
                <Nav className="mx-auto">
                        <Nav.Link eventKey={1} href="home">Home</Nav.Link>
                        <Nav.Link eventKey={2} href="aboutus">About Us</Nav.Link>
                        <Nav.Link eventKey={3} href="services">Services</Nav.Link>
                        {/* <Nav.Link eventKey={2} href="featuredlisting">Featured listing</Nav.Link> */}
                        <Nav.Link eventKey={4} href="testimonial">Testimonial</Nav.Link>
                        <NavDropdown title="Transaction" id="collasible-nav-dropdown">
                            {/* <NavDropdown.Item eventKey={4} href="Bedok">Bedok</NavDropdown.Item>
                            <NavDropdown.Item eventKey={5} href="Bedok3rm">Bedok (3rm)</NavDropdown.Item>
                            <NavDropdown.Item eventKey={6} href="Bedok4rm">Bedok (4rm)</NavDropdown.Item>
                            <NavDropdown.Item eventKey={7} href="Bedok5rm">Bedok (5rm)</NavDropdown.Item>
                            <NavDropdown.Item eventKey={8} href="BedokExe">Bedok (Executive)</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item eventKey={9} href="Tampines">Tampines</NavDropdown.Item>
                            <NavDropdown.Item eventKey={20} href="Tam3rm">Tampines (3rm)</NavDropdown.Item>
                            <NavDropdown.Item eventKey={11} href="Tam4rm">Tampines (4rm)</NavDropdown.Item>
                            <NavDropdown.Item eventKey={12} href="Tam5rm">Tampines (5rm)</NavDropdown.Item>
                            <NavDropdown.Item eventKey={13} href="TamExe">Tampines (Executive)</NavDropdown.Item>
                            <NavDropdown.Divider /> */}
                            <NavDropdown.Item eventKey={5} href="Clem">Clementi</NavDropdown.Item>
                            {/* <NavDropdown.Item eventKey={6} href="newlaunch">New Launch</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item eventKey={7} href="aboutus">Landed</NavDropdown.Item> */}
                        </NavDropdown>
                </Nav>
                <Nav>
                    <Nav.Link eventKey={8} href="contactus">Contact Us</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;
