

import React from "react";

const ServiceProvided:React.FC = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: 45, fontFamily: 'Barlow', backgroundColor: 'white', paddingTop: 5 }}>
            <div style={{
                minHeight: '50%',
                display: 'flex',
                flexDirection: 'row',
                flexFlow: 'wrap',
                justifyContent: 'space-evenly',
                margin: 15,
                }}>
                    <div style={{
                        textAlign: 'center',
                        alignSelf: 'center',
                        flexBasis: '100%',
                    }}>
                        <h3><u>SERVICE PROVIDED</u> </h3>
                    </div>
                    <div style={{
                        textAlign: 'center',
                        alignSelf: 'center',
                        padding: 10,
                        margin: 5,
                    }}>
                        <h4> Free Services </h4>
                        <br/>
                        <div>1 to 1 Consultation (Any Location)</div>
                        <div>Financial Calculations</div>
                        <div>Timeline Planning</div>
                    </div>
                    <div style={{
                        textAlign: 'center',
                        alignSelf: 'center',
                        padding: 10,
                        margin: 5,
                    }}>
                        <h4> Market Analysis </h4>
                        <br/>
                        <div>Property Valuation</div>
                        <div>Asset Planning</div>
                        <div>Financial Calculations</div>
                    </div>
                    <div style={{
                        textAlign: 'center',
                        alignSelf: 'center',
                        padding: 10,
                        margin: 5,
                    }}>
                        <h4> Home Staging </h4>
                        <br/>
                        <div>Professional Home Photoshoots</div>
                        <div>360 Photoshoot Digital Photoshoot</div>
                        <div>Premium Home Photoshop Editing Service</div>
                        <div>*Professional Home Staging Services</div>
                        <div>*Professional Home Video Tour</div>
                    </div>
                    <div style={{
                        textAlign: 'center',
                        alignSelf: 'center',
                        padding: 10,
                        margin: 5,
                    }}>
                        <h4> Digital Marketing </h4>
                        <br/>
                        <div>Premium Online Marketing Platform</div>
                        <div>Strategic Pricing</div>
                        <div>Intensive Marketing Service</div>
                        <div>Unique Marketing Service</div>
                    </div>
            </div>
        </div>
    );
};

export default ServiceProvided;
