

import React from "react";
import { Field, Form, Formik } from "formik";
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import axios from 'axios';

const ContactUs = () => {

  const navigate = useNavigate();
  // Error Component
  const errorMessage = (error: any) => {
    return <div  style={{ color: 'red' }}>{error}</div>;
  };

  const handleFormSubmit = (values: any) => (
    axios.post('https://ppc-backend.propertylawrence.com/contact', values)
  );

    return (
      <div style={{
        width: '100%',
        maxWidth: 800,
        marginTop: 15,
        padding: 20,
        fontFamily: 'Barlow',
        backgroundColor: 'rgb(211,211,211, 0.2)',
        boxShadow: `
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12)`
      }}>
      <Formik
        initialValues={{
          name: undefined,
          phoneNo: undefined,
          email: undefined,
          enquiry: undefined,
          project: 'Personal Webpage',
          agreetoterms: true,
      }}

      validationSchema={Yup.object().shape({
        name: Yup.string()
        //   .email()
          .required("Required"),
          phoneNo: Yup.string()
          .required("No phone number provided.")
          .min(8, "number incorrect - should be 8 numbers minimum.")
          .matches(/[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/, "Phone number must contain only numbers."),
          // recieveinfo: Yup.boolean().isTrue('This is required'),
          agreetoterms: Yup.boolean().isTrue('This is required'),
      })}

      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        const result = await handleFormSubmit(values);
        if (result.status === 201) {
          console.log(result);
          navigate('/thankyou');
          actions.setSubmitting(false);
        } else {
          console.log(result);
          navigate('/thankyou');
        }
        // actions.resetForm();
      }}
      >
        {({ errors,
            submitForm,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
          <div className="container">
            <div >
              <h3>CONTACT ME </h3>
              <h5>FOR A <u>FREE VALUATION REPORT!</u></h5>
              <h6>+65 8484 5999 | LawrenceNgERA@gmail.com</h6>
              <br />
            </div>
            <div >
              <Form onSubmit={() => handleSubmit()}>
                <div className="form-group">
                  <label style={{ fontWeight: 800, color: 'grey'}}>Name: *</label>
                  <Field
                    className="form-control"
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.name && errorMessage(errors.name)}
                </div>
                <div className="form-group">
                  <label style={{ fontWeight: 800, color: 'grey'}}>Email:</label>
                  <Field
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && errorMessage(errors.email)}
                </div>
                <div className="form-group">
                  <label style={{ fontWeight: 800, color: 'grey'}}>Phone Number: *</label>
                  <Field
                    className="form-control"
                    type="tel"
                    placeholder="Mobile number"
                    name="phoneNo"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.phoneNo &&
                    errorMessage(errors.phoneNo)}
                </div>
                <div className="form-group">
                  <label style={{ fontWeight: 800, color: 'grey'}}>Enquiry: </label>
                  <Field
                    component="textarea"
                    className="form-control"
                    name="enquiry"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div style={{ display: 'flex' }} className="form-group">
                  <Field
                    style={{ alignSelf: 'center', marginRight: 25 }} 
                    type="checkbox"
                    placeholder="Terms & Conditions"
                    name="agreetoterms"
                    id="customCheck2"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label style={{ alignSelf: 'flex-end', marginTop: 10 }} htmlFor="customCheck2"> 
                  By submitting this form, I have read and agree to the <a href="https://propertylawrence.com/privacy-policy" >Privacy Policy *</a>
                  {errors.agreetoterms && errorMessage(errors.agreetoterms)}
                  </label>
                </div>
                <div className="form-group">
                  <button className="btn btn-primary" type="button" onClick={() => {submitForm()}}>
                    SEND
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
      </div>
      );
};

export default ContactUs;
