import React from 'react';

const Footer = () => {
    return (
        <footer className="py-5 bg-transparent">
            <div style={{
                marginBotttom: 125,
                marginTop: 25,
                }} className="container">
                <p style={{ fontWeight: '800', fontSize: 14 }} className="m-1 text-center">Singapore</p>
                <div style={{
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                }} >
                    <p style={{ fontWeight: '800' }} className="m-0 text-center">ERA REALTY NETWORK PTE LTD</p>
                    <div 
                        style={{
                            margin: 0,
                            fontSize: 12,
                            textAlign: 'center',
                            alignSelf: 'center',
                            fontWeight: '500',
                        }} >
                        Copyright © Property Lawrence 2021
                    </div>
                    <div 
                        style={{
                            paddingBottom: 25,
                            margin: 0,
                            fontSize: 12,
                            textAlign: 'center',
                            alignSelf: 'center',
                            fontWeight: '500',
                        }} >
                        All Rights Reserved
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;