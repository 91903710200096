
import fp from '../../images/webFrontPic.png';
import awardPic1 from '../../images/awardPic1.jpeg';
import clementHDB1 from '../../images/clementiHDB1.jpeg';
import ServiceProvided from '../services/service';
import Testimonial from '../testimonial/testimonial';
import ContactPage from '../contactus/ContectPage';

const Home:React.FC = () => {
    const winHeight = window.innerHeight;
    // const winwWidth = window.innerWidth;
    return (
        <div>
            <div
                style={{
                    zIndex: 1,
                    top: winHeight/2.8,
                    maxWidth: '100%',
                    marginLeft: '5%',
                    marginRight: '5%',
                    minHeight: winHeight/2,
                    maxHeight: winHeight/1.8,
                    display: 'flex',
                    flexDirection: 'row',
                    flexFlow: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
            }}>
                <div style={{
                    textAlign: 'center',
                    alignSelf: 'center', fontFamily: 'Barlow',
                    fontSize: 24, backgroundColor: 'rgba(255, 255, 255, 0.84)',
                    maxWidth: 450, border: 'white 1px solid', paddingLeft: 10,
                    paddingRight: 10, paddingTop: 5, paddingBottom: 5,
                    fontWeight: 500,
                }}>
                    Lawrence - The Most Recommended Property Agent In Clementi
                </div>
            </div>
            <div style={{
                    zIndex: 0, width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    flexFlow: 'wrap',
                    justifyContent: 'center',
                    // alignItems: 'center',
                    alignContent: 'flex-start',
                    position: 'relative',
                    // marginBottom: 5,
            }}>
                <img style={{
                    // position: 'absolute',
                    // top: 0, flex:1,
                    width: '100%',
                    // for full height img
                    minHeight: winHeight/1.8,
                    maxHeight: winHeight/1.4,
                    zIndex: -1,
                    // select cover for full height img
                    objectFit:'cover',
                    objectPosition: '65% 35%',
                    // border: '2px solid red'
                    }} src={clementHDB1} alt="Clementi HDB">
                </img>

                <div
                    style={{
                        zIndex: 1,
                        position: 'absolute',
                        top: 25,
                        marginLeft: '5%',
                        marginRight: '5%',
                        display: 'flex',
                        flexDirection: 'row',
                        flexFlow: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontFamily: 'barlow',
                        fontSize: 20, backgroundColor: 'rgba(255, 255, 255, 0.60)',
                        // fontWeight: 400,
                }}>
                    <div style={{ 
                        textAlign: 'center',
                        alignSelf: 'flex-start',
                        maxWidth: 650, paddingLeft: 15, marginRight: 25,
                        paddingRight: 15, paddingTop: 5, paddingBottom: 5,
                        // fontWeight: 600,
                    }}>
                        Clementi Recent Transactions:
                    </div>
                    <div style={{ 
                        textAlign: 'start',
                        alignSelf: 'center',
                        maxWidth: 650, paddingLeft: 15,
                        paddingRight: 15, paddingTop: 5, paddingBottom: 5,
                        // fontWeight: 600,
                    }}>
                        3 RM | $350K - $700K
                        <br />
                        4 RM | $550K - $900K
                        <br />
                        5 RM | $700K - $1 MILLION ++
                        <br />
                    </div>
                    
                </div>
            </div>
            <div style={{
                // marginBottom: 5,
                minHeight: winHeight/2.6,
                maxHeight: winHeight/1.4,
                padding: 20,
                backgroundColor: '#412749',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                fontSize: 18, fontFamily: 'barlow',
                color: 'white',
                }}>
                <div style={{
                    width: '75%',
                    textAlign: 'center',
                    alignSelf: 'center',
                    fontSize: 24,
                    fontWeight: 500,
                    }}>
                    Get Lawrence to Sell Your Flat at the Best Price
                </div>
                <div style={{
                    marginTop: 25,
                    width: '75%',
                    textAlign: 'center',
                    alignSelf: 'center',
                    fontSize: (winHeight / 50),
                    }}>
                    Best Rated Customer Satisfaction in Clementi
                </div>
                <form action="https://wa.me/+6584845999">
                    <input style={{
                    textAlign: 'center',
                    alignSelf: 'center',
                    // backgroundColor: 'rgb(64, 246, 3)',
                    backgroundColor: 'white', marginTop: 15,
                    maxWidth: 210, border: 'white 1px solid', paddingLeft: 10,
                    paddingRight: 10, paddingTop: 5, paddingBottom: 5, color: 'black',
                    flexWrap: 'wrap',
                }} type="submit" value="Call Lawrence Now" />
                </form>
            </div>
            <div>
                <img style={{
                        // position: 'absolute',
                        // top: 0, flex:1,
                        width: '100%',
                        // for full height img
                        minHeight: winHeight/1.3,
                        maxHeight: winHeight/1.1,
                        zIndex: -1,
                        // select cover for full height img
                        objectFit:'cover',
                        objectPosition: '50% 50%',
                        // border: '2px solid red'
                        }} src={awardPic1} alt="Lawrence">
                    </img>
            </div>
            <div style={{
                // marginTop: 5,
                padding: 20,
                minHeight: winHeight/2.6,
                backgroundColor: '#9da4b0',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                fontSize: 18, fontFamily: 'Barloww',
                color: 'white',
                }}>
                <div style={{
                    width: '75%',
                    textAlign: 'center',
                    alignSelf: 'center',
                    fontSize: (winHeight / 38), fontFamily: 'Barlow',
                    }}>
                    " All properties are unique in its own way. Each can have its own demand. 
                    Our team here at Property Lawrence believe there is no property too hard to sell. "
                </div>
                <form action="https://wa.me/+6584845999">
                    <input style={{
                    textAlign: 'center',
                    alignSelf: 'center',
                    // backgroundColor: 'rgb(64, 246, 3)',
                    backgroundColor: 'white', marginTop: 15,
                    maxWidth: 210, border: 'white 1px solid', paddingLeft: 10,
                    paddingRight: 10, paddingTop: 5, paddingBottom: 5, color: 'black',
                    flexWrap: 'wrap', fontFamily: 'Barlow',
                }} type="submit" value="Call Lawrence Now" />
                </form>
            </div>
            <ServiceProvided />
            <Testimonial />

            <div style={{
                    zIndex: 0, width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    flexFlow: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    // marginBottom: 5,
            }}>
                <img style={{
                    // position: 'absolute',
                    // top: 0, flex:1,
                    width: '100%',
                    // for full height img
                    minHeight: winHeight/1.8,
                    maxHeight: winHeight/1.4,
                    zIndex: -1,
                    // select cover for full height img
                    objectFit:'cover',
                    objectPosition: '82% 20%',
                    // border: '2px solid red'
                    }} src={fp} alt="Lawrence">
                </img>
                <div
                    style={{
                        zIndex: 1,
                        position: 'absolute',
                        top: winHeight/3.3,
                        maxWidth: 450,
                        marginLeft: '5%',
                        marginRight: '5%',
                        display: 'flex',
                        flexDirection: 'row',
                        flexFlow: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                }}>
                    <form action="https://wa.me/+6584845999">
                        <input style={{
                            textAlign: 'center',
                            alignSelf: 'center',
                            backgroundColor: 'rgb(64, 246, 3)',
                            maxWidth: 210, border: 'white 1px solid', paddingLeft: 10,
                            paddingRight: 10, paddingTop: 5, paddingBottom: 5, color: 'white',
                            borderRadius: 5, flexWrap: 'wrap',
                        }} type="submit" value="Call Lawrence Now" />
                    </form>
                </div>
            </div>
            <ContactPage />
        </div>
    );
};

export default Home;
