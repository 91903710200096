import React from 'react';

import {BrowserRouter, Route, Routes} from 'react-router-dom';

// import './App.scss';
import Footer from './pages/components/Footer/Footer';
import Header from './pages/components/Header/Header';
import Home from './pages/home/Home';
import ContactUs from './pages/contactus/ContactUs';
import AboutUs from './pages/about/AboutUs';
import HdbBedok3rm from './pages/pastTransaction/hdbBedok3rm';
import HdbTampines from './pages/pastTransaction/hdbTampines';
import HdbBedok4rm from './pages/pastTransaction/hdhBedok4rm';
import HdbBedok5rm from './pages/pastTransaction/hdbBedok5rm';
import HdbBedokExe from './pages/pastTransaction/hdbBedokExe';
import HdbBedok from './pages/pastTransaction/hdbBedok';
import Testimonial from './pages/testimonial/testimonial';
import HdbTam3rm from './pages/pastTransaction/hdbTam3rm';
import HdbTam4rm from './pages/pastTransaction/hdbTam4rm';
import HdbTam5rm from './pages/pastTransaction/hdbTam5rm';
import HdbTamExe from './pages/pastTransaction/hdbTamExe';
import HdbClem from './pages/pastTransaction/hdbClem';
import ServiceProvided from './pages/services/service';
import PrivacyPolicy from './pages/contactus/PrivacyPolicy';
import ContactPage from './pages/contactus/ContectPage';
import ThankYouPage from './pages/contactus/ThankYouPage';
import { FaPhoneAlt } from 'react-icons/fa';


const App = () => {
  return (
      <BrowserRouter>
        <div style={{ fontSize: 12, paddingLeft: 25, paddingTop: 5,
                paddingBottom: 5, fontFamily: 'Arial',
                backgroundColor: '#27044a', color: 'white',
                fontWeight: 600,
        }}> <FaPhoneAlt style={{ marginRight: 5 }} />
            <a
                href="tel:+6584845999"
                target="_blank"
                rel="noopener noreferrer"
                style={{  color: 'white' }}
            >
                HOTLINE : +65 8484 5999
            </a>
        </div>
        <React.Fragment>
            <Header />
            <Routes>
                <Route path={'/'} element={<Home />}/>
                <Route path={'/home'} element={<Home />}/>
                <Route path={'/services'} element={<ServiceProvided />}/>
                <Route path={'/aboutus'} element={<AboutUs />}/>
                <Route path={'/contactus'} element={<ContactPage />}/>
                <Route path={'/listing'} element={<ContactUs />}/>
                <Route path={'/transaction'} element={<ContactUs />}/>
                <Route path={'/testimonial'} element={<Testimonial />}/>
                <Route path={'/Bedok'} element={<HdbBedok />}/>
                <Route path={'/Bedok3rm'} element={<HdbBedok3rm />}/>
                <Route path={'/Bedok4rm'} element={<HdbBedok4rm />}/>
                <Route path={'/Bedok5rm'} element={<HdbBedok5rm />}/>
                <Route path={'/BedokExe'} element={<HdbBedokExe />}/>
                <Route path={'/Tam3rm'} element={<HdbTam3rm />}/>
                <Route path={'/Tam4rm'} element={<HdbTam4rm />}/>
                <Route path={'/Tam5rm'} element={<HdbTam5rm />}/>
                <Route path={'/TamExe'} element={<HdbTamExe />}/>
                <Route path={'/Tampines'} element={<HdbTampines />}/>
                <Route path={'/Clem'} element={<HdbClem />}/>
                <Route path={'/privacy-policy'} element={<PrivacyPolicy />}/>
                <Route path={'/thankyou'} element={<ThankYouPage />}/>
                Testimonial
                {/* <Route exact path={'/products/:id'} component={ProductDetail}/>
                <Route exact patr={'/cart'} component={ShoppingCart}/> */}
            </Routes>
            <a
                href="https://wa.me/+6584845999"
                style={{
                    position: 'fixed',
                    width: 60,
                    height: 60,
                    bottom: 40,
                    right: 40,
                    backgroundColor: '#25d366',
                    color:'#FFF',
                    borderRadius: 50,
                    textAlign: 'center',
                    fontSize: 30,
                    boxShadow: '2px 2px 3px #999',
                    zIndex: 100,
                }}
                // className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
                <i style={{ marginTop:16 }} className="fa fa-whatsapp whatsapp-icon"></i>
            </a>
            {/* <a
                href="https://wa.me/+6584845999"
                style={{
                    position: 'fixed',
                    width: 60,
                    height: 60,
                    bottom: 120,
                    right: 40,
                    backgroundColor: '#72BB32E',
                    color:'#FFF',
                    borderRadius: 50,
                    textAlign: 'center',
                    fontSize: 30,
                    boxShadow: '2px 2px 3px #999',
                    zIndex: 100,
                }}
                // className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
                

                <i className="fa fa-wechat" style={{ marginTop:16, fontSize: 28 }}></i>
            </a> */}
            <Footer/>
        </React.Fragment>
        </BrowserRouter>
  );
}

export default App;
