export const threeRm = [
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 310 CLEMENTI AVENUE 4",
        "Type": "3-ROOM",
        "Model": "NG",
        "Size": "67",
        "PSF": "452",
        "Price": "326,000",
        "Date": "19-Mar-21",
        "PSM": "4,865"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 376 CLEMENTI AVENUE 4",
        "Type": "3-ROOM",
        "Model": "NG",
        "Size": "67",
        "PSF": "492",
        "Price": "355,000",
        "Date": "19-Mar-21",
        "PSM": "5,296"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 435 CLEMENTI AVENUE 3",
        "Type": "3-ROOM",
        "Model": "NG",
        "Size": "67",
        "PSF": "534",
        "Price": "385,000",
        "Date": "19-Mar-21",
        "PSM": "5,748"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 510 WEST COAST DRIVE",
        "Type": "3-ROOM",
        "Model": "NG",
        "Size": "67",
        "PSF": "416",
        "Price": "300,000",
        "Date": "15-Mar-21",
        "PSM": "4,478"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 428 CLEMENTI AVENUE 3",
        "Type": "3-ROOM",
        "Model": "NG",
        "Size": "67",
        "PSF": "485",
        "Price": "350,000",
        "Date": "12-Mar-21",
        "PSM": "5,221"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 308 CLEMENTI AVENUE 4",
        "Type": "3-ROOM",
        "Model": "NG",
        "Size": "67",
        "PSF": "427",
        "Price": "308,000",
        "Date": "12-Mar-21",
        "PSM": "4,596"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 450 CLEMENTI AVENUE 3",
        "Type": "3-ROOM",
        "Model": "NG",
        "Size": "68",
        "PSF": "445",
        "Price": "325,000",
        "Date": "10-Mar-21",
        "PSM": "4,790"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 308 CLEMENTI AVENUE 4",
        "Type": "3-ROOM",
        "Model": "NG",
        "Size": "67",
        "PSF": "427",
        "Price": "308,000",
        "Date": "10-Mar-21",
        "PSM": "4,596"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 308 CLEMENTI AVENUE 4",
        "Type": "3-ROOM",
        "Model": "NG",
        "Size": "67",
        "PSF": "423",
        "Price": "305,000",
        "Date": "8-Mar-21",
        "PSM": "4,553"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 363 CLEMENTI AVENUE 2",
        "Type": "3-ROOM",
        "Model": "NG",
        "Size": "67",
        "PSF": "444",
        "Price": "320,000",
        "Date": "8-Mar-21",
        "PSM": "4,779"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 430 CLEMENTI AVENUE 3",
        "Type": "3-ROOM",
        "Model": "NG",
        "Size": "67",
        "PSF": "465",
        "Price": "335,000",
        "Date": "5-Mar-21",
        "PSM": "5,005"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 346 CLEMENTI AVENUE 5",
        "Type": "3-ROOM",
        "Model": "NG",
        "Size": "67",
        "PSF": "455",
        "Price": "328,000",
        "Date": "5-Mar-21",
        "PSM": "4,898"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 450 CLEMENTI AVENUE 3",
        "Type": "3-ROOM",
        "Model": "NG",
        "Size": "68",
        "PSF": "496",
        "Price": "362,500",
        "Date": "5-Mar-21",
        "PSM": "5,339"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 421 CLEMENTI AVENUE 1",
        "Type": "3-ROOM",
        "Model": "MODEL A",
        "Size": "73",
        "PSF": "684",
        "Price": "536,888",
        "Date": "3-Mar-21",
        "PSM": "7,363"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 706 CLEMENTI WEST STREET 2",
        "Type": "3-ROOM",
        "Model": "NG",
        "Size": "68",
        "PSF": "390",
        "Price": "285,000",
        "Date": "26-Feb-21",
        "PSM": "4,198"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 303 CLEMENTI AVENUE 4",
        "Type": "3-ROOM",
        "Model": "NG",
        "Size": "67",
        "PSF": "444",
        "Price": "320,000",
        "Date": "26-Feb-21",
        "PSM": "4,779"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 608 CLEMENTI WEST STREET 1",
        "Type": "3-ROOM",
        "Model": "NG",
        "Size": "67",
        "PSF": "444",
        "Price": "320,000",
        "Date": "24-Feb-21",
        "PSM": "4,779"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 728 CLEMENTI WEST STREET 2",
        "Type": "3-ROOM",
        "Model": "NG",
        "Size": "82",
        "PSF": "361",
        "Price": "318,000",
        "Date": "24-Feb-21",
        "PSM": "3,886"
    }
];
export const fourRm = [
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 311B CLEMENTI AVENUE 4",
        "Type": "4-ROOM",
        "Model": "DBSS",
        "Size": "80",
        "PSF": "859",
        "Price": "740,000",
        "Date": "19-Mar-21",
        "PSM": "9,246"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 373 CLEMENTI AVENUE 4",
        "Type": "4-ROOM",
        "Model": "NG",
        "Size": "92",
        "PSF": "475",
        "Price": "470,000",
        "Date": "19-Mar-21",
        "PSM": "5,113"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 460 CLEMENTI AVENUE 3",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "85",
        "PSF": "753",
        "Price": "688,000",
        "Date": "17-Mar-21",
        "PSM": "8,105"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 420 CLEMENTI AVENUE 1",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "96",
        "PSF": "774",
        "Price": "800,000",
        "Date": "17-Mar-21",
        "PSM": "8,331"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 302 CLEMENTI AVENUE 4",
        "Type": "4-ROOM",
        "Model": "NG",
        "Size": "92",
        "PSF": "424",
        "Price": "420,000",
        "Date": "15-Mar-21",
        "PSM": "4,564"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 417 CLEMENTI AVENUE 1",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "100",
        "PSF": "697",
        "Price": "750,000",
        "Date": "12-Mar-21",
        "PSM": "7,503"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 607 CLEMENTI WEST STREET 1",
        "Type": "4-ROOM",
        "Model": "NG",
        "Size": "92",
        "PSF": "430",
        "Price": "426,000",
        "Date": "12-Mar-21",
        "PSM": "4,629"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 506 WEST COAST DRIVE",
        "Type": "4-ROOM",
        "Model": "NG",
        "Size": "83",
        "PSF": "403",
        "Price": "360,000",
        "Date": "12-Mar-21",
        "PSM": "4,338"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 411 COM-WEALTH AVENUE WEST",
        "Type": "4-ROOM",
        "Model": "NG",
        "Size": "97",
        "PSF": "383",
        "Price": "400,000",
        "Date": "12-Mar-21",
        "PSM": "4,123"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 708 CLEMENTI WEST STREET 2",
        "Type": "4-ROOM",
        "Model": "NG",
        "Size": "91",
        "PSF": "395",
        "Price": "386,888",
        "Date": "10-Mar-21",
        "PSM": "4,252"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 461 CLEMENTI AVENUE 3",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "90",
        "PSF": "755",
        "Price": "731,000",
        "Date": "8-Mar-21",
        "PSM": "8,127"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 311C CLEMENTI AVENUE 4",
        "Type": "4-ROOM",
        "Model": "DBSS",
        "Size": "80",
        "PSF": "865",
        "Price": "745,000",
        "Date": "3-Mar-21",
        "PSM": "9,311"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 411 COM-WEALTH AVENUE WEST",
        "Type": "4-ROOM",
        "Model": "NG",
        "Size": "97",
        "PSF": "410",
        "Price": "428,088",
        "Date": "3-Mar-21",
        "PSM": "4,413"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 712 CLEMENTI WEST STREET 2",
        "Type": "4-ROOM",
        "Model": "NG",
        "Size": "91",
        "PSF": "424",
        "Price": "415,000",
        "Date": "26-Feb-21",
        "PSM": "4,564"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 420 CLEMENTI AVENUE 1",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "91",
        "PSF": "644",
        "Price": "630,000",
        "Date": "26-Feb-21",
        "PSM": "6,932"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 381 CLEMENTI AVENUE 5",
        "Type": "4-ROOM",
        "Model": "NG",
        "Size": "91",
        "PSF": "550",
        "Price": "538,000",
        "Date": "26-Feb-21",
        "PSM": "5,920"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 410 COM-WEALTH AVENUE WEST",
        "Type": "4-ROOM",
        "Model": "NG",
        "Size": "97",
        "PSF": "398",
        "Price": "415,000",
        "Date": "26-Feb-21",
        "PSM": "4,284"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 350 CLEMENTI AVENUE 2",
        "Type": "4-ROOM",
        "Model": "NG",
        "Size": "92",
        "PSF": "412",
        "Price": "408,000",
        "Date": "22-Feb-21",
        "PSM": "4,435"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 730 CLEMENTI WEST STREET 2",
        "Type": "4-ROOM",
        "Model": "NG",
        "Size": "93",
        "PSF": "420",
        "Price": "420,000",
        "Date": "22-Feb-21",
        "PSM": "4,521"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 441B CLEMENTI AVENUE 3",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "93",
        "PSF": "879",
        "Price": "880,000",
        "Date": "19-Feb-21",
        "PSM": "9,462"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 311C CLEMENTI AVENUE 4",
        "Type": "4-ROOM",
        "Model": "DBSS",
        "Size": "82",
        "PSF": "873",
        "Price": "770,000",
        "Date": "17-Feb-21",
        "PSM": "9,397"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 462 CLEMENTI AVENUE 3",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "90",
        "PSF": "750",
        "Price": "725,888",
        "Date": "15-Feb-21",
        "PSM": "8,073"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 311A CLEMENTI AVENUE 4",
        "Type": "4-ROOM",
        "Model": "DBSS",
        "Size": "80",
        "PSF": "886",
        "Price": "763,000",
        "Date": "12-Feb-21",
        "PSM": "9,537"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 311B CLEMENTI AVENUE 4",
        "Type": "4-ROOM",
        "Model": "DBSS",
        "Size": "81",
        "PSF": "835",
        "Price": "727,000",
        "Date": "12-Feb-21",
        "PSM": "8,988"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 118 CLEMENTI STREET 13",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "104",
        "PSF": "496",
        "Price": "555,000",
        "Date": "12-Feb-21",
        "PSM": "5,339"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 311B CLEMENTI AVENUE 4",
        "Type": "4-ROOM",
        "Model": "DBSS",
        "Size": "81",
        "PSF": "867",
        "Price": "755,000",
        "Date": "12-Feb-21",
        "PSM": "9,332"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 311B CLEMENTI AVENUE 4",
        "Type": "4-ROOM",
        "Model": "DBSS",
        "Size": "82",
        "PSF": "828",
        "Price": "730,000",
        "Date": "10-Feb-21",
        "PSM": "8,913"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 304 CLEMENTI AVENUE 4",
        "Type": "4-ROOM",
        "Model": "NG",
        "Size": "92",
        "PSF": "374",
        "Price": "370,000",
        "Date": "10-Feb-21",
        "PSM": "4,026"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 716 CLEMENTI WEST STREET 2",
        "Type": "4-ROOM",
        "Model": "NG",
        "Size": "91",
        "PSF": "420",
        "Price": "411,000",
        "Date": "5-Feb-21",
        "PSM": "4,521"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 708 CLEMENTI WEST STREET 2",
        "Type": "4-ROOM",
        "Model": "NG",
        "Size": "91",
        "PSF": "419",
        "Price": "410,000",
        "Date": "5-Feb-21",
        "PSM": "4,510"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 411 COM-WEALTH AVENUE WEST",
        "Type": "4-ROOM",
        "Model": "NG",
        "Size": "98",
        "PSF": "468",
        "Price": "493,000",
        "Date": "3-Feb-21",
        "PSM": "5,038"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 316 CLEMENTI AVENUE 4",
        "Type": "4-ROOM",
        "Model": "NG",
        "Size": "91",
        "PSF": "544",
        "Price": "533,000",
        "Date": "3-Feb-21",
        "PSM": "5,856"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 101 CLEMENTI STREET 14",
        "Type": "4-ROOM",
        "Model": "NG",
        "Size": "98",
        "PSF": "527",
        "Price": "555,000",
        "Date": "1-Feb-21",
        "PSM": "5,673"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 311C CLEMENTI AVENUE 4",
        "Type": "4-ROOM",
        "Model": "DBSS",
        "Size": "82",
        "PSF": "737",
        "Price": "650,000",
        "Date": "1-Feb-21",
        "PSM": "7,933"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 311B CLEMENTI AVENUE 4",
        "Type": "4-ROOM",
        "Model": "DBSS",
        "Size": "82",
        "PSF": "881",
        "Price": "777,000",
        "Date": "29-Jan-21",
        "PSM": "9,483"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 311C CLEMENTI AVENUE 4",
        "Type": "4-ROOM",
        "Model": "DBSS",
        "Size": "82",
        "PSF": "890",
        "Price": "785,000",
        "Date": "27-Jan-21",
        "PSM": "9,580"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 611 CLEMENTI WEST STREET 1",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "103",
        "PSF": "388",
        "Price": "430,000",
        "Date": "27-Jan-21",
        "PSM": "4,176"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 425 CLEMENTI AVENUE 1",
        "Type": "4-ROOM",
        "Model": "MODEL A",
        "Size": "100",
        "PSF": "687",
        "Price": "738,888",
        "Date": "22-Jan-21",
        "PSM": "7,395"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 311C CLEMENTI AVENUE 4",
        "Type": "4-ROOM",
        "Model": "DBSS",
        "Size": "82",
        "PSF": "848",
        "Price": "748,000",
        "Date": "22-Jan-21",
        "PSM": "9,128"
    }
];
export const fiveRm = [
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 340 CLEMENTI AVENUE 5",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "392",
        "Price": "510,000",
        "Date": "12-Mar-21",
        "PSM": "4,219"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 340 CLEMENTI AVENUE 5",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "369",
        "Price": "480,000",
        "Date": "10-Mar-21",
        "PSM": "3,972"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 416 CLEMENTI AVENUE 1",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "113",
        "PSF": "743",
        "Price": "903,000",
        "Date": "5-Mar-21",
        "PSM": "7,998"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 338 CLEMENTI AVENUE 2",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "119",
        "PSF": "551",
        "Price": "705,000",
        "Date": "5-Mar-21",
        "PSM": "5,931"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 337 CLEMENTI AVENUE 2",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "119",
        "PSF": "547",
        "Price": "700,000",
        "Date": "3-Mar-21",
        "PSM": "5,888"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 114 CLEMENTI STREET 13",
        "Type": "5-ROOM",
        "Model": "MODEL A-MAISON",
        "Size": "142",
        "PSF": "497",
        "Price": "760,000",
        "Date": "24-Feb-21",
        "PSM": "5,350"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 114 CLEMENTI STREET 13",
        "Type": "5-ROOM",
        "Model": "MODEL A-MAISON",
        "Size": "142",
        "PSF": "543",
        "Price": "830,000",
        "Date": "22-Feb-21",
        "PSM": "5,845"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 311B CLEMENTI AVENUE 4",
        "Type": "5-ROOM",
        "Model": "DBSS",
        "Size": "105",
        "PSF": "848",
        "Price": "958,000",
        "Date": "19-Feb-21",
        "PSM": "9,128"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 415 COM-WEALTH AVENUE WEST",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "137",
        "PSF": "494",
        "Price": "728,000",
        "Date": "15-Feb-21",
        "PSM": "5,317"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 415 COM-WEALTH AVENUE WEST",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "137",
        "PSF": "461",
        "Price": "680,000",
        "Date": "10-Feb-21",
        "PSM": "4,962"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 440C CLEMENTI AVENUE 3",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "112",
        "PSF": "909",
        "Price": "1,095,000",
        "Date": "5-Feb-21",
        "PSM": "9,784"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 416 CLEMENTI AVENUE 1",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "118",
        "PSF": "715",
        "Price": "908,000",
        "Date": "5-Feb-21",
        "PSM": "7,696"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 311C CLEMENTI AVENUE 4",
        "Type": "5-ROOM",
        "Model": "DBSS",
        "Size": "105",
        "PSF": "828",
        "Price": "936,000",
        "Date": "3-Feb-21",
        "PSM": "8,913"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 311C CLEMENTI AVENUE 4",
        "Type": "5-ROOM",
        "Model": "DBSS",
        "Size": "105",
        "PSF": "919",
        "Price": "1,038,000",
        "Date": "3-Feb-21",
        "PSM": "9,892"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 461 CLEMENTI AVENUE 3",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "110",
        "PSF": "674",
        "Price": "798,000",
        "Date": "29-Jan-21",
        "PSM": "7,255"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 311B CLEMENTI AVENUE 4",
        "Type": "5-ROOM",
        "Model": "DBSS",
        "Size": "105",
        "PSF": "821",
        "Price": "928,000",
        "Date": "22-Jan-21",
        "PSM": "8,837"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 311C CLEMENTI AVENUE 4",
        "Type": "5-ROOM",
        "Model": "DBSS",
        "Size": "105",
        "PSF": "788",
        "Price": "890,000",
        "Date": "20-Jan-21",
        "PSM": "8,482"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 342 CLEMENTI AVENUE 5",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "121",
        "PSF": "330",
        "Price": "430,000",
        "Date": "18-Jan-21",
        "PSM": "3,552"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 440C CLEMENTI AVENUE 3",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "112",
        "PSF": "896",
        "Price": "1,080,000",
        "Date": "15-Jan-21",
        "PSM": "9,645"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 357 CLEMENTI AVENUE 2",
        "Type": "5-ROOM",
        "Model": "STANDARD",
        "Size": "117",
        "PSF": "588",
        "Price": "740,000",
        "Date": "13-Jan-21",
        "PSM": "6,329"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 311A CLEMENTI AVENUE 4",
        "Type": "5-ROOM",
        "Model": "DBSS",
        "Size": "105",
        "PSF": "864",
        "Price": "975,888",
        "Date": "11-Jan-21",
        "PSM": "9,300"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 413 COM-WEALTH AVENUE WEST",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "125",
        "PSF": "431",
        "Price": "580,000",
        "Date": "8-Jan-21",
        "PSM": "4,639"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 416 CLEMENTI AVENUE 1",
        "Type": "5-ROOM",
        "Model": "IMPROVED",
        "Size": "113",
        "PSF": "751",
        "Price": "913,000",
        "Date": "1-Jan-21",
        "PSM": "8,084"
    }
];
export const exe = [
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 206 CLEMENTI AVENUE 6",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "148",
        "PSF": "480",
        "Price": "765,000",
        "Date": "2-Mar-21",
        "PSM": "5,167"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 116 CLEMENTI STREET 13",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "151",
        "PSF": "540",
        "Price": "878,000",
        "Date": "26-Feb-21",
        "PSM": "5,813"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 111 CLEMENTI STREET 13",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "147",
        "PSF": "528",
        "Price": "835,000",
        "Date": "26-Feb-21",
        "PSM": "5,683"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 102 CLEMENTI STREET 14",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "155",
        "PSF": "516",
        "Price": "860,000",
        "Date": "26-Feb-21",
        "PSM": "5,554"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 115 CLEMENTI STREET 13",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "149",
        "PSF": "523",
        "Price": "838,000",
        "Date": "12-Feb-21",
        "PSM": "5,630"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 116 CLEMENTI STREET 13",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "146",
        "PSF": "519",
        "Price": "816,000",
        "Date": "22-Jan-21",
        "PSM": "5,587"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 116 CLEMENTI STREET 13",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "147",
        "PSF": "480",
        "Price": "760,000",
        "Date": "20-Jan-21",
        "PSM": "5,167"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 115 CLEMENTI STREET 13",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "149",
        "PSF": "499",
        "Price": "800,000",
        "Date": "13-Jan-21",
        "PSM": "5,371"
    },
    {
        "Town": "CLEMENTI",
        "StreetName": "BLK 206 CLEMENTI AVENUE 6",
        "Type": "EXECUTIVE",
        "Model": "MAISONETTE",
        "Size": "150",
        "PSF": "480",
        "Price": "775,000",
        "Date": "8-Jan-21",
        "PSM": "5,167"
    }
];