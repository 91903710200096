

import React from "react";
import ContactUs from "./ContactUs";

const ContactPage: React.FC = () => {

    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 25}}>
            <ContactUs />
        </div>
      );
};

export default ContactPage;
