

import React from "react";
import testiPic1 from '../../images/testiphoto1.png'
import testiPic2 from '../../images/testiphoto2.png'

const Testimonial:React.FC = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: 45, fontFamily: 'Barlow', backgroundColor: '#f7faf0', paddingTop: 25 }}>
            <h3>TESTIMONIAL</h3>
            <div style={{
                display: 'flex', alignContent: 'center',
                flexDirection: 'row', flexFlow: 'wrap',
                marginBottom: 15, marginTop: 35,
                justifyContent: 'space-evenly',  width: '100%'}}>
                <div style={{ maxWidth: 500, alignSelf: 'flex-start', padding: 5, marginBottom: 35 }}>
                I am so glad that I have Lawrence to assist me in finding my very first home. He is meticulous and patient on choosing the suitable properties for me that are able to fulfill my requirements.
                 To add on, he is very he is very knowledgeable and would advise me accordingly based on his expertise. Being a very satisfied client of his, I would totally recommend anyone to look out for him.
                    <div><span style={{ fontStyle: 'italic'}}>
                        <img
                            height='80px'
                            src={testiPic2} alt='customer pic 2'>
                        </img><br/> - Mr liau (Excutive Condominum Buyer)</span>
                    </div>
                </div>
                <div style={{ maxWidth: 500, alignSelf: 'flex-start', padding: 5, marginBottom: 35 }}>
                    Lawrence is very patient and clearly understands his client's needs. He is very prompt in his response and is always proactively helping me to solve any queries. 
                    I will not be hesitating to refer him to others who are looking for a property
                    <div> <span style={{ fontStyle: 'italic'}}>
                    <img
                        height='80px'
                        src={testiPic1} alt='customer pic 1'>
                    </img><br/>- Mr Lu (HDB Home Owner)</span></div>
                </div>
                {/* <div style={{ maxWidth: 500, alignSelf: 'flex-start', padding: 5, marginBottom: 35 }}>
                    Lawrence helped me with my property planning and I manage to further understand the procedure to sell my house. 
                    Strongly recommend!
                    <div> <span style={{ fontStyle: 'italic'}}>
                    <img
                        height='80px'
                        src={testiPic1} alt='customer pic 1'>
                    </img><br/>- Mr Guo (Buyer)</span> </div>
                </div> */}
            </div>
        </div>
    );
};

export default Testimonial;
