
const PrivacyPolicy = () => {
  return (
    <div>
    <div style={{ marginTop: 35, padding: 15, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <h2 style={{ textAlign: 'center', marginBottom: 35, color: 'grey' }}>
            PRIVACY POLICY
        </h2>
        <div style={{ textAlign: 'justify', maxWidth: 1000, color: 'grey', alignSelf: 'center', fontSize: 16 }}>
            <p>
            This Privacy Policy describes how we manage Personal Data in compliance with the Singapore Personal Data Protection Act 2012 (No. 26 of 2012) (“Act”).
            </p>
            <p>
            We encourage you to read this Privacy Policy so that you know and understand the purposes for which we collect, use and disclose your Personal Data.
            </p>

            <p>
            We may update this Privacy Policy from time to time. Subject to your rights at law, you agree to be bound by the prevailing terms of this Privacy Policy as updated from time to time. We encourage you to check the latest version of this Privacy Policy regularly.
            </p>
            <p>
            “Personal Data” refers to data, whether true or not, about an individual who can be identified from that data, or from that data in combination with other information to which the organization may have access and includes the meaning otherwise as defined in the Act as amended from time to time.
            </p>
            By signing up for or using any services or campaigns offered by us, or submitting information to or otherwise communicating with us, you agree and consent to our representatives collecting, using and disclosing your Personal Data in accordance with this Privacy Policy. “You” or “your” means any individual to whom the Act applies and includes an individual actual or prospective customer but excludes any corporate entity (including corporate customers) and any other entity that is excluded under the Act.
            <p>
            This Privacy Policy does not supersede or replace any other consents you may have previously or separately provided to us in respect of your Personal Data, and your consent to this Privacy Policy is in addition to any other rights which any of the Companies may have at law to collect, use or disclose your Personal Data. This Privacy Policy and your use of this website shall be governed in all respects by the laws of Singapore.
            </p>
            <p>
            TYPE OF INFORMATION WE COLLECT
            </p>
            <p>
            There are several areas where you can submit information to us. “Personal Data” contained in this Privacy Policy shall mean your name, telephone/mobile number, email address and any other information that personally identifies you. In this website, there are forms available to you to submit your interest by providing your Personal Data. By submitting such Personal Data, it is understood that you permit us to contact you, regardeless of whether the contact details are registered with the Do Not Call (DNC) Registry. If you have specific instructions on how you would like to be contacted by us, please indicate so in the comments box provided in the form.
            </p>
            <p>
            WHEN AND WHOM WE MAY SHARE YOUR INFORMATION
            </p>
            <p>
            In the event you require additional services which are provided by other vendors, individuals and representatives, you agree to us sharing your Personal Data for the specific purposes outlined. All vendors must agree to implement and maintain reasonable security procedures and practices appropriate to protect your Personal Data from unauthorised access, destruction, use, modification or disclosure. We will also disclose your Personal Data where required to do so by law, in response to court orders, subpoenas or other legal obgliations, when requested to do so by law enforcement agencies.
            </p>
            <p>
            ACCEPTANCE OF THIS PRIVACY POLICY
            </p>
            <p>
            In accordance with the Personal Data Protection Act 2012 (PDPA) its amendments and DNC regulation, by submitting your Personal Data to us, you are consenting for us to contact you by phone (Call, SMS, Whatsapp) and email at the contact details provided. We reserve all rights to change, modify, add or remove portions of this Privacy Policy at any time without prior notice. Thank you for reading.
            </p>
        </div>
    </div>
    <div style={{ height: 100 }}> </div>
    </div>
  );
}

export default PrivacyPolicy;
