
export const threeRm = [
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 1 BEDOK SOUTH AVENUE 1",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "59",
                    "PSF": "422",
                    "Price": " $268,000 ",
                    "Date": "18-Jan-21",
                    "PSM": "4,542"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 554 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "363",
                    "Price": " $265,000 ",
                    "Date": "18-Jan-21",
                    "PSM": "3,907"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 115 BEDOK NORTH ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "73",
                    "PSF": "399",
                    "Price": " $313,000 ",
                    "Date": "18-Jan-21",
                    "PSM": "4,295"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 110 BEDOK NORTH ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "416",
                    "Price": " $300,000 ",
                    "Date": "18-Jan-21",
                    "PSM": "4,478"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 30 NEW UPPER CHANGI ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "65",
                    "PSF": "429",
                    "Price": " $300,000 ",
                    "Date": "15-Jan-21",
                    "PSM": "4,618"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 134 BEDOK NORTH STREET 2",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "433",
                    "Price": " $312,000 ",
                    "Date": "15-Jan-21",
                    "PSM": "4,661"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 104 BEDOK NORTH AVENUE 4",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "402",
                    "Price": " $290,000 ",
                    "Date": "15-Jan-21",
                    "PSM": "4,327"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 40 BEDOK SOUTH ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "465",
                    "Price": " $335,000 ",
                    "Date": "15-Jan-21",
                    "PSM": "5,005"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 416 BEDOK NORTH AVENUE 2",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "423",
                    "Price": " $305,000 ",
                    "Date": "13-Jan-21",
                    "PSM": "4,553"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 58 CHAI CHEE DRIVE",
                    "Type": "3-ROOM",
                    "Model": "Model A",
                    "Size": "75",
                    "PSF": "397",
                    "Price": " $320,000 ",
                    "Date": "13-Jan-21",
                    "PSM": "4,273"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 22 BEDOK SOUTH AVENUE 1",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "73",
                    "PSF": "433",
                    "Price": " $340,000 ",
                    "Date": "13-Jan-21",
                    "PSM": "4,661"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 104 BEDOK NORTH AVENUE 4",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "388",
                    "Price": " $280,000 ",
                    "Date": "11-Jan-21",
                    "PSM": "4,176"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 526 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "399",
                    "Price": " $288,000 ",
                    "Date": "11-Jan-21",
                    "PSM": "4,295"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 416 BEDOK NORTH AVENUE 2",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "399",
                    "Price": " $288,000 ",
                    "Date": "11-Jan-21",
                    "PSM": "4,295"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 522 BEDOK NORTH AVENUE 1",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "416",
                    "Price": " $300,000 ",
                    "Date": "8-Jan-21",
                    "PSM": "4,478"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 33 BEDOK SOUTH AVENUE 2",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "88",
                    "PSF": "380",
                    "Price": " $360,000 ",
                    "Date": "6-Jan-21",
                    "PSM": "4,090"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 529 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "82",
                    "PSF": "363",
                    "Price": " $320,000 ",
                    "Date": "6-Jan-21",
                    "PSM": "3,907"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 620 BEDOK RESERVOIR ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "60",
                    "PSF": "431",
                    "Price": " $278,000 ",
                    "Date": "6-Jan-21",
                    "PSM": "4,639"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 525 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "309",
                    "Price": " $223,000 ",
                    "Date": "30-Dec-20",
                    "PSM": "3,326"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 808A CHAI CHEE ROAD",
                    "Type": "3-ROOM",
                    "Model": "Model A",
                    "Size": "68",
                    "PSF": "616",
                    "Price": " $450,000 ",
                    "Date": "30-Dec-20",
                    "PSM": "6,631"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 19 CHAI CHEE ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "65",
                    "PSF": "372",
                    "Price": " $260,000 ",
                    "Date": "30-Dec-20",
                    "PSM": "4,004"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 151 BEDOK RESERVOIR ROAD",
                    "Type": "3-ROOM",
                    "Model": "SIMPLIFIED",
                    "Size": "63",
                    "PSF": "435",
                    "Price": " $295,000 ",
                    "Date": "30-Dec-20",
                    "PSM": "4,682"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 105 BEDOK NORTH AVENUE 4",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "82",
                    "PSF": "363",
                    "Price": " $320,000 ",
                    "Date": "28-Dec-20",
                    "PSM": "3,907"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 533 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "383",
                    "Price": " $280,000 ",
                    "Date": "28-Dec-20",
                    "PSM": "4,123"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 29 NEW UPPER CHANGI ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "65",
                    "PSF": "441",
                    "Price": " $308,000 ",
                    "Date": "28-Dec-20",
                    "PSM": "4,747"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 26 NEW UPPER CHANGI ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "66",
                    "PSF": "408",
                    "Price": " $290,000 ",
                    "Date": "28-Dec-20",
                    "PSM": "4,392"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 808B CHAI CHEE ROAD",
                    "Type": "3-ROOM",
                    "Model": "Model A",
                    "Size": "68",
                    "PSF": "643",
                    "Price": " $470,000 ",
                    "Date": "28-Dec-20",
                    "PSM": "6,921"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 110 BEDOK NORTH ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "82",
                    "PSF": "408",
                    "Price": " $360,000 ",
                    "Date": "28-Dec-20",
                    "PSM": "4,392"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 616 BEDOK RESERVOIR ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "421",
                    "Price": " $308,000 ",
                    "Date": "25-Dec-20",
                    "PSM": "4,532"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 30 NEW UPPER CHANGI ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "65",
                    "PSF": "436",
                    "Price": " $305,000 ",
                    "Date": "25-Dec-20",
                    "PSM": "4,693"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 423 BEDOK NORTH AVENUE 1",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "81",
                    "PSF": "358",
                    "Price": " $312,000 ",
                    "Date": "25-Dec-20",
                    "PSM": "3,854"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 41 CHAI CHEE STREET",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "405",
                    "Price": " $292,000 ",
                    "Date": "25-Dec-20",
                    "PSM": "4,359"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 712 BEDOK RESERVOIR ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "400",
                    "Price": " $292,500 ",
                    "Date": "23-Dec-20",
                    "PSM": "4,306"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 122 BEDOK NORTH STREET 2",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "82",
                    "PSF": "383",
                    "Price": " $338,000 ",
                    "Date": "23-Dec-20",
                    "PSM": "4,123"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 533 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "390",
                    "Price": " $285,000 ",
                    "Date": "23-Dec-20",
                    "PSM": "4,198"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 424 BEDOK NORTH AVENUE 1",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "388",
                    "Price": " $280,000 ",
                    "Date": "23-Dec-20",
                    "PSM": "4,176"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 1 BEDOK SOUTH AVENUE 1",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "59",
                    "PSF": "472",
                    "Price": " $300,000 ",
                    "Date": "18-Dec-20",
                    "PSM": "5,081"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 525 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "82",
                    "PSF": "385",
                    "Price": " $340,000 ",
                    "Date": "16-Dec-20",
                    "PSM": "4,144"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 546 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "369",
                    "Price": " $270,000 ",
                    "Date": "16-Dec-20",
                    "PSM": "3,972"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 610 BEDOK RESERVOIR ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "381",
                    "Price": " $275,000 ",
                    "Date": "16-Dec-20",
                    "PSM": "4,101"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 412 BEDOK NORTH AVENUE 2",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "82",
                    "PSF": "363",
                    "Price": " $320,000 ",
                    "Date": "16-Dec-20",
                    "PSM": "3,907"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 21 CHAI CHEE ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "65",
                    "PSF": "386",
                    "Price": " $270,000 ",
                    "Date": "16-Dec-20",
                    "PSM": "4,155"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 504 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "59",
                    "PSF": "394",
                    "Price": " $250,000 ",
                    "Date": "16-Dec-20",
                    "PSM": "4,241"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 503 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "59",
                    "PSF": "409",
                    "Price": " $260,000 ",
                    "Date": "16-Dec-20",
                    "PSM": "4,402"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 104 BEDOK NORTH AVENUE 4",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "81",
                    "PSF": "344",
                    "Price": " $300,000 ",
                    "Date": "16-Dec-20",
                    "PSM": "3,703"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 27 NEW UPPER CHANGI ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "65",
                    "PSF": "422",
                    "Price": " $295,000 ",
                    "Date": "16-Dec-20",
                    "PSM": "4,542"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 425 BEDOK NORTH ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "82",
                    "PSF": "397",
                    "Price": " $350,000 ",
                    "Date": "14-Dec-20",
                    "PSM": "4,273"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 709 BEDOK RESERVOIR ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "409",
                    "Price": " $299,000 ",
                    "Date": "11-Dec-20",
                    "PSM": "4,402"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 86 BEDOK NORTH STREET 4",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "381",
                    "Price": " $275,000 ",
                    "Date": "11-Dec-20",
                    "PSM": "4,101"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 536 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "409",
                    "Price": " $299,000 ",
                    "Date": "11-Dec-20",
                    "PSM": "4,402"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 426 BEDOK NORTH ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "388",
                    "Price": " $280,000 ",
                    "Date": "11-Dec-20",
                    "PSM": "4,176"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 609 BEDOK RESERVOIR ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "395",
                    "Price": " $285,000 ",
                    "Date": "9-Dec-20",
                    "PSM": "4,252"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 76 BEDOK NORTH ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "59",
                    "PSF": "433",
                    "Price": " $275,000 ",
                    "Date": "9-Dec-20",
                    "PSM": "4,661"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 401 BEDOK NORTH AVENUE 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "388",
                    "Price": " $280,000 ",
                    "Date": "9-Dec-20",
                    "PSM": "4,176"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 40 BEDOK SOUTH ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "430",
                    "Price": " $310,000 ",
                    "Date": "9-Dec-20",
                    "PSM": "4,629"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 105 BEDOK NORTH AVENUE 4",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "82",
                    "PSF": "385",
                    "Price": " $340,000 ",
                    "Date": "9-Dec-20",
                    "PSM": "4,144"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 21 CHAI CHEE ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "65",
                    "PSF": "379",
                    "Price": " $265,000 ",
                    "Date": "9-Dec-20",
                    "PSM": "4,080"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 50 CHAI CHEE STREET",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "351",
                    "Price": " $256,888 ",
                    "Date": "7-Dec-20",
                    "PSM": "3,778"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 529 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "390",
                    "Price": " $285,000 ",
                    "Date": "4-Dec-20",
                    "PSM": "4,198"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 545 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "363",
                    "Price": " $265,000 ",
                    "Date": "4-Dec-20",
                    "PSM": "3,907"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 27 NEW UPPER CHANGI ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "65",
                    "PSF": "443",
                    "Price": " $310,000 ",
                    "Date": "4-Dec-20",
                    "PSM": "4,768"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 117 BEDOK NORTH ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "73",
                    "PSF": "400",
                    "Price": " $314,000 ",
                    "Date": "4-Dec-20",
                    "PSM": "4,306"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 550 BEDOK NORTH AVENUE 1",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "82",
                    "PSF": "374",
                    "Price": " $330,000 ",
                    "Date": "4-Dec-20",
                    "PSM": "4,026"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 92 BEDOK NORTH AVENUE 4",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "82",
                    "PSF": "346",
                    "Price": " $305,000 ",
                    "Date": "4-Dec-20",
                    "PSM": "3,724"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 704 BEDOK RESERVOIR ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "402",
                    "Price": " $294,000 ",
                    "Date": "4-Dec-20",
                    "PSM": "4,327"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 510 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "368",
                    "Price": " $265,000 ",
                    "Date": "2-Dec-20",
                    "PSM": "3,961"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 27 NEW UPPER CHANGI ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "65",
                    "PSF": "408",
                    "Price": " $285,000 ",
                    "Date": "2-Dec-20",
                    "PSM": "4,392"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 41 CHAI CHEE STREET",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "416",
                    "Price": " $300,000 ",
                    "Date": "2-Dec-20",
                    "PSM": "4,478"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 138 BEDOK NORTH STREET 2",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "82",
                    "PSF": "391",
                    "Price": " $345,000 ",
                    "Date": "1-Dec-20",
                    "PSM": "4,209"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 503 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "59",
                    "PSF": "391",
                    "Price": " $248,000 ",
                    "Date": "1-Dec-20",
                    "PSM": "4,209"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 18 BEDOK SOUTH ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "65",
                    "PSF": "393",
                    "Price": " $275,000 ",
                    "Date": "1-Dec-20",
                    "PSM": "4,230"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 704 BEDOK RESERVOIR ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "390",
                    "Price": " $285,000 ",
                    "Date": "1-Dec-20",
                    "PSM": "4,198"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 18 BEDOK SOUTH ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "65",
                    "PSF": "429",
                    "Price": " $300,000 ",
                    "Date": "1-Dec-20",
                    "PSM": "4,618"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 32 CHAI CHEE AVENUE",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "65",
                    "PSF": "429",
                    "Price": " $300,000 ",
                    "Date": "1-Dec-20",
                    "PSM": "4,618"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 36 CHAI CHEE AVENUE",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "65",
                    "PSF": "401",
                    "Price": " $280,000 ",
                    "Date": "30-Nov-20",
                    "PSM": "4,316"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 77 BEDOK NORTH ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "60",
                    "PSF": "434",
                    "Price": " $280,000 ",
                    "Date": "30-Nov-20",
                    "PSM": "4,672"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 808B CHAI CHEE ROAD",
                    "Type": "3-ROOM",
                    "Model": "Model A",
                    "Size": "68",
                    "PSF": "657",
                    "Price": " $480,000 ",
                    "Date": "27-Nov-20",
                    "PSM": "7,072"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 532 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "405",
                    "Price": " $296,000 ",
                    "Date": "27-Nov-20",
                    "PSM": "4,359"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 213 BEDOK NORTH STREET 1",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "83",
                    "PSF": "431",
                    "Price": " $385,000 ",
                    "Date": "27-Nov-20",
                    "PSM": "4,639"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 418 BEDOK NORTH AVENUE 2",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "402",
                    "Price": " $290,000 ",
                    "Date": "27-Nov-20",
                    "PSM": "4,327"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 18 BEDOK SOUTH ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "65",
                    "PSF": "422",
                    "Price": " $295,000 ",
                    "Date": "27-Nov-20",
                    "PSM": "4,542"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 536 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "394",
                    "Price": " $288,000 ",
                    "Date": "25-Nov-20",
                    "PSM": "4,241"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 520 BEDOK NORTH AVENUE 1",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "354",
                    "Price": " $255,000 ",
                    "Date": "25-Nov-20",
                    "PSM": "3,810"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 504 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "59",
                    "PSF": "398",
                    "Price": " $252,500 ",
                    "Date": "25-Nov-20",
                    "PSM": "4,284"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 214 BEDOK NORTH STREET 1",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "472",
                    "Price": " $345,000 ",
                    "Date": "23-Nov-20",
                    "PSM": "5,081"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 213 BEDOK NORTH STREET 1",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "503",
                    "Price": " $368,000 ",
                    "Date": "23-Nov-20",
                    "PSM": "5,414"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 70 BEDOK SOUTH ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "388",
                    "Price": " $280,000 ",
                    "Date": "23-Nov-20",
                    "PSM": "4,176"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 525 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "377",
                    "Price": " $272,000 ",
                    "Date": "23-Nov-20",
                    "PSM": "4,058"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 107 BEDOK RESERVOIR ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "377",
                    "Price": " $272,000 ",
                    "Date": "23-Nov-20",
                    "PSM": "4,058"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 29 NEW UPPER CHANGI ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "65",
                    "PSF": "391",
                    "Price": " $273,000 ",
                    "Date": "23-Nov-20",
                    "PSM": "4,209"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 116 BEDOK NORTH ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "73",
                    "PSF": "376",
                    "Price": " $295,000 ",
                    "Date": "20-Nov-20",
                    "PSM": "4,047"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 105 BEDOK RESERVOIR ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "409",
                    "Price": " $295,000 ",
                    "Date": "20-Nov-20",
                    "PSM": "4,402"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 201 BEDOK NORTH STREET 1",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "73",
                    "PSF": "465",
                    "Price": " $365,000 ",
                    "Date": "20-Nov-20",
                    "PSM": "5,005"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 530 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "356",
                    "Price": " $260,000 ",
                    "Date": "20-Nov-20",
                    "PSM": "3,832"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 27 NEW UPPER CHANGI ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "65",
                    "PSF": "415",
                    "Price": " $290,000 ",
                    "Date": "20-Nov-20",
                    "PSM": "4,467"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 545 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "367",
                    "Price": " $268,000 ",
                    "Date": "20-Nov-20",
                    "PSM": "3,950"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 526 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "399",
                    "Price": " $288,000 ",
                    "Date": "20-Nov-20",
                    "PSM": "4,295"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 808B CHAI CHEE ROAD",
                    "Type": "3-ROOM",
                    "Model": "Model A",
                    "Size": "68",
                    "PSF": "657",
                    "Price": " $480,000 ",
                    "Date": "20-Nov-20",
                    "PSM": "7,072"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 210 NEW UPPER CHANGI ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "454",
                    "Price": " $332,000 ",
                    "Date": "18-Nov-20",
                    "PSM": "4,887"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 21 CHAI CHEE ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "65",
                    "PSF": "412",
                    "Price": " $288,000 ",
                    "Date": "18-Nov-20",
                    "PSM": "4,435"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 709 BEDOK RESERVOIR ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "82",
                    "PSF": "397",
                    "Price": " $350,000 ",
                    "Date": "18-Nov-20",
                    "PSM": "4,273"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 533 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "397",
                    "Price": " $290,000 ",
                    "Date": "16-Nov-20",
                    "PSM": "4,273"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 103 BEDOK NORTH AVENUE 4",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "393",
                    "Price": " $283,000 ",
                    "Date": "16-Nov-20",
                    "PSM": "4,230"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 529 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "342",
                    "Price": " $250,000 ",
                    "Date": "16-Nov-20",
                    "PSM": "3,681"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 402 BEDOK NORTH AVENUE 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "82",
                    "PSF": "374",
                    "Price": " $330,000 ",
                    "Date": "16-Nov-20",
                    "PSM": "4,026"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 55 CHAI CHEE DRIVE",
                    "Type": "3-ROOM",
                    "Model": "Model A",
                    "Size": "76",
                    "PSF": "370",
                    "Price": " $303,000 ",
                    "Date": "16-Nov-20",
                    "PSM": "3,983"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 701 BEDOK RESERVOIR ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "390",
                    "Price": " $285,000 ",
                    "Date": "13-Nov-20",
                    "PSM": "4,198"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 529 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "410",
                    "Price": " $300,000 ",
                    "Date": "13-Nov-20",
                    "PSM": "4,413"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 418 BEDOK NORTH AVENUE 2",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "444",
                    "Price": " $320,000 ",
                    "Date": "13-Nov-20",
                    "PSM": "4,779"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 55 CHAI CHEE DRIVE",
                    "Type": "3-ROOM",
                    "Model": "Model A",
                    "Size": "76",
                    "PSF": "355",
                    "Price": " $290,000 ",
                    "Date": "13-Nov-20",
                    "PSM": "3,821"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 75 BEDOK NORTH ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "59",
                    "PSF": "400",
                    "Price": " $254,000 ",
                    "Date": "13-Nov-20",
                    "PSM": "4,306"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 23 BEDOK SOUTH AVENUE 1",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "73",
                    "PSF": "446",
                    "Price": " $350,000 ",
                    "Date": "11-Nov-20",
                    "PSM": "4,801"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 504 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "59",
                    "PSF": "370",
                    "Price": " $235,000 ",
                    "Date": "11-Nov-20",
                    "PSM": "3,983"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 18 BEDOK SOUTH ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "65",
                    "PSF": "472",
                    "Price": " $330,000 ",
                    "Date": "11-Nov-20",
                    "PSM": "5,081"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 402 BEDOK NORTH AVENUE 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "388",
                    "Price": " $280,000 ",
                    "Date": "6-Nov-20",
                    "PSM": "4,176"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 537 BEDOK NORTH STREET 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "343",
                    "Price": " $251,000 ",
                    "Date": "6-Nov-20",
                    "PSM": "3,692"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 33 BEDOK SOUTH AVENUE 2",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "73",
                    "PSF": "368",
                    "Price": " $289,000 ",
                    "Date": "6-Nov-20",
                    "PSM": "3,961"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 703 BEDOK RESERVOIR ROAD",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "406",
                    "Price": " $297,000 ",
                    "Date": "4-Nov-20",
                    "PSM": "4,370"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 620 BEDOK RESERVOIR ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "68",
                    "PSF": "335",
                    "Price": " $245,000 ",
                    "Date": "4-Nov-20",
                    "PSM": "3,606"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 75 BEDOK NORTH ROAD",
                    "Type": "3-ROOM",
                    "Model": "IMPROVED",
                    "Size": "70",
                    "PSF": "378",
                    "Price": " $285,000 ",
                    "Date": "4-Nov-20",
                    "PSM": "4,069"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 45 CHAI CHEE STREET",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "68",
                    "PSF": "367",
                    "Price": " $268,000 ",
                    "Date": "4-Nov-20",
                    "PSM": "3,950"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 807A CHAI CHEE ROAD",
                    "Type": "3-ROOM",
                    "Model": "Model A",
                    "Size": "68",
                    "PSF": "572",
                    "Price": " $418,000 ",
                    "Date": "1-Nov-20",
                    "PSM": "6,157"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 401 BEDOK NORTH AVENUE 3",
                    "Type": "3-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "67",
                    "PSF": "388",
                    "Price": " $280,000 ",
                    "Date": "1-Nov-20",
                    "PSM": "4,176"
                }
            ];
export const fourRm = [
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 131 BEDOK NORTH AVENUE 3",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "460",
                    "Price": " $455,000 ",
                    "Date": "18-Jan-21",
                    "PSM": "4,951"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 183 BEDOK NORTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "92",
                    "PSF": "535",
                    "Price": " $530,000 ",
                    "Date": "18-Jan-21",
                    "PSM": "5,759"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 50 CHAI CHEE STREET",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "94",
                    "PSF": "372",
                    "Price": " $376,000 ",
                    "Date": "18-Jan-21",
                    "PSM": "4,004"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 43 CHAI CHEE STREET",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "354",
                    "Price": " $350,000 ",
                    "Date": "18-Jan-21",
                    "PSM": "3,810"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 774 BEDOK RESERVOIR VIEW",
                    "Type": "4-ROOM",
                    "Model": "PREMIUM APARTMENT",
                    "Size": "105",
                    "PSF": "491",
                    "Price": " $555,000 ",
                    "Date": "18-Jan-21",
                    "PSM": "5,285"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 51 CHAI CHEE STREET",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "98",
                    "PSF": "353",
                    "Price": " $372,000 ",
                    "Date": "18-Jan-21",
                    "PSM": "3,800"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 143 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "104",
                    "PSF": "366",
                    "Price": " $410,000 ",
                    "Date": "18-Jan-21",
                    "PSM": "3,940"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 766 BEDOK RESERVOIR VIEW",
                    "Type": "4-ROOM",
                    "Model": "PREMIUM APARTMENT",
                    "Size": "105",
                    "PSF": "494",
                    "Price": " $558,000 ",
                    "Date": "18-Jan-21",
                    "PSM": "5,317"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 220B BEDOK CENTRAL",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "97",
                    "PSF": "642",
                    "Price": " $670,000 ",
                    "Date": "15-Jan-21",
                    "PSM": "6,910"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 165 BEDOK SOUTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "105",
                    "PSF": "469",
                    "Price": " $530,000 ",
                    "Date": "15-Jan-21",
                    "PSM": "5,048"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 808C CHAI CHEE ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "93",
                    "PSF": "659",
                    "Price": " $660,000 ",
                    "Date": "15-Jan-21",
                    "PSM": "7,093"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 807B CHAI CHEE ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "93",
                    "PSF": "654",
                    "Price": " $655,000 ",
                    "Date": "15-Jan-21",
                    "PSM": "7,040"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 219B BEDOK CENTRAL",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "97",
                    "PSF": "592",
                    "Price": " $618,000 ",
                    "Date": "15-Jan-21",
                    "PSM": "6,372"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 95 BEDOK NORTH AVENUE 4",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "343",
                    "Price": " $340,000 ",
                    "Date": "15-Jan-21",
                    "PSM": "3,692"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 763 BEDOK RESERVOIR VIEW",
                    "Type": "4-ROOM",
                    "Model": "PREMIUM APARTMENT",
                    "Size": "105",
                    "PSF": "496",
                    "Price": " $560,500 ",
                    "Date": "13-Jan-21",
                    "PSM": "5,339"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 64 NEW UPPER CHANGI ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "422",
                    "Price": " $418,000 ",
                    "Date": "13-Jan-21",
                    "PSM": "4,542"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 93 BEDOK NORTH AVENUE 4",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "364",
                    "Price": " $360,000 ",
                    "Date": "13-Jan-21",
                    "PSM": "3,918"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 403 BEDOK NORTH AVENUE 3",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "414",
                    "Price": " $410,000 ",
                    "Date": "13-Jan-21",
                    "PSM": "4,456"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 128 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "SIMPLIFIED",
                    "Size": "84",
                    "PSF": "415",
                    "Price": " $375,000 ",
                    "Date": "13-Jan-21",
                    "PSM": "4,467"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 765 BEDOK RESERVOIR VIEW",
                    "Type": "4-ROOM",
                    "Model": "PREMIUM APARTMENT",
                    "Size": "105",
                    "PSF": "463",
                    "Price": " $523,000 ",
                    "Date": "13-Jan-21",
                    "PSM": "4,984"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 131 BEDOK NORTH AVENUE 3",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "432",
                    "Price": " $428,000 ",
                    "Date": "13-Jan-21",
                    "PSM": "4,650"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 547 BEDOK NORTH STREET 3",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "359",
                    "Price": " $355,000 ",
                    "Date": "13-Jan-21",
                    "PSM": "3,864"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 157 BEDOK SOUTH AVENUE 3",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "105",
                    "PSF": "425",
                    "Price": " $480,000 ",
                    "Date": "13-Jan-21",
                    "PSM": "4,575"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 808B CHAI CHEE ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "93",
                    "PSF": "623",
                    "Price": " $624,000 ",
                    "Date": "11-Jan-21",
                    "PSM": "6,706"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 808C CHAI CHEE ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "93",
                    "PSF": "653",
                    "Price": " $654,000 ",
                    "Date": "11-Jan-21",
                    "PSM": "7,029"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 122 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "SIMPLIFIED",
                    "Size": "84",
                    "PSF": "398",
                    "Price": " $360,000 ",
                    "Date": "8-Jan-21",
                    "PSM": "4,284"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 125 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "SIMPLIFIED",
                    "Size": "84",
                    "PSF": "454",
                    "Price": " $410,000 ",
                    "Date": "8-Jan-21",
                    "PSM": "4,887"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 37 BEDOK SOUTH AVENUE 2",
                    "Type": "4-ROOM",
                    "Model": "IMPROVED",
                    "Size": "84",
                    "PSF": "376",
                    "Price": " $340,000 ",
                    "Date": "8-Jan-21",
                    "PSM": "4,047"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 43 CHAI CHEE STREET",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "389",
                    "Price": " $385,000 ",
                    "Date": "8-Jan-21",
                    "PSM": "4,187"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 808C CHAI CHEE ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "88",
                    "PSF": "618",
                    "Price": " $585,000 ",
                    "Date": "8-Jan-21",
                    "PSM": "6,652"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 131 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "SIMPLIFIED",
                    "Size": "83",
                    "PSF": "420",
                    "Price": " $375,000 ",
                    "Date": "8-Jan-21",
                    "PSM": "4,521"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 769 BEDOK RESERVOIR VIEW",
                    "Type": "4-ROOM",
                    "Model": "PREMIUM APARTMENT",
                    "Size": "105",
                    "PSF": "447",
                    "Price": " $505,000 ",
                    "Date": "6-Jan-21",
                    "PSM": "4,812"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 108 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "91",
                    "PSF": "393",
                    "Price": " $385,000 ",
                    "Date": "6-Jan-21",
                    "PSM": "4,230"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 221A BEDOK CENTRAL",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "94",
                    "PSF": "582",
                    "Price": " $588,000 ",
                    "Date": "6-Jan-21",
                    "PSM": "6,265"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 130 BEDOK NORTH STREET 2",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "444",
                    "Price": " $440,000 ",
                    "Date": "6-Jan-21",
                    "PSM": "4,779"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 712 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "93",
                    "PSF": "410",
                    "Price": " $410,000 ",
                    "Date": "6-Jan-21",
                    "PSM": "4,413"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 221A BEDOK CENTRAL",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "97",
                    "PSF": "670",
                    "Price": " $700,000 ",
                    "Date": "6-Jan-21",
                    "PSM": "7,212"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 43 CHAI CHEE STREET",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "384",
                    "Price": " $380,000 ",
                    "Date": "6-Jan-21",
                    "PSM": "4,133"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 121 BEDOK NORTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "364",
                    "Price": " $360,000 ",
                    "Date": "30-Dec-20",
                    "PSM": "3,918"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 402 BEDOK NORTH AVENUE 3",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "421",
                    "Price": " $416,500 ",
                    "Date": "30-Dec-20",
                    "PSM": "4,532"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 623 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "91",
                    "PSF": "398",
                    "Price": " $390,000 ",
                    "Date": "30-Dec-20",
                    "PSM": "4,284"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 169 BEDOK SOUTH AVENUE 3",
                    "Type": "4-ROOM",
                    "Model": "SIMPLIFIED",
                    "Size": "83",
                    "PSF": "454",
                    "Price": " $405,000 ",
                    "Date": "30-Dec-20",
                    "PSM": "4,887"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 428 BEDOK NORTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "414",
                    "Price": " $410,000 ",
                    "Date": "30-Dec-20",
                    "PSM": "4,456"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 140 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "SIMPLIFIED",
                    "Size": "84",
                    "PSF": "420",
                    "Price": " $380,000 ",
                    "Date": "30-Dec-20",
                    "PSM": "4,521"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 25 CHAI CHEE ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "104",
                    "PSF": "518",
                    "Price": " $580,000 ",
                    "Date": "30-Dec-20",
                    "PSM": "5,576"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 104 BEDOK NORTH AVENUE 4",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "91",
                    "PSF": "370",
                    "Price": " $362,000 ",
                    "Date": "28-Dec-20",
                    "PSM": "3,983"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 221B BEDOK CENTRAL",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "97",
                    "PSF": "667",
                    "Price": " $696,000 ",
                    "Date": "23-Dec-20",
                    "PSM": "7,180"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 108 BEDOK NORTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "91",
                    "PSF": "347",
                    "Price": " $340,000 ",
                    "Date": "23-Dec-20",
                    "PSM": "3,735"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 219C BEDOK CENTRAL",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "97",
                    "PSF": "623",
                    "Price": " $650,000 ",
                    "Date": "18-Dec-20",
                    "PSM": "6,706"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 807C CHAI CHEE ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "93",
                    "PSF": "619",
                    "Price": " $620,000 ",
                    "Date": "18-Dec-20",
                    "PSM": "6,663"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 82 BEDOK NORTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "384",
                    "Price": " $380,000 ",
                    "Date": "18-Dec-20",
                    "PSM": "4,133"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 428 BEDOK NORTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "379",
                    "Price": " $375,000 ",
                    "Date": "18-Dec-20",
                    "PSM": "4,080"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 672 JALAN DAMAI",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "108",
                    "PSF": "396",
                    "Price": " $460,000 ",
                    "Date": "18-Dec-20",
                    "PSM": "4,263"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 807A CHAI CHEE ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "93",
                    "PSF": "649",
                    "Price": " $650,000 ",
                    "Date": "18-Dec-20",
                    "PSM": "6,986"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 15 BEDOK SOUTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "IMPROVED",
                    "Size": "88",
                    "PSF": "412",
                    "Price": " $390,000 ",
                    "Date": "18-Dec-20",
                    "PSM": "4,435"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 403 BEDOK NORTH AVENUE 3",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "409",
                    "Price": " $405,000 ",
                    "Date": "16-Dec-20",
                    "PSM": "4,402"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 611 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "99",
                    "PSF": "364",
                    "Price": " $388,000 ",
                    "Date": "16-Dec-20",
                    "PSM": "3,918"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 808C CHAI CHEE ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "93",
                    "PSF": "619",
                    "Price": " $620,000 ",
                    "Date": "16-Dec-20",
                    "PSM": "6,663"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 131 BEDOK NORTH AVENUE 3",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "392",
                    "Price": " $388,000 ",
                    "Date": "16-Dec-20",
                    "PSM": "4,219"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 95 BEDOK NORTH AVENUE 4",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "343",
                    "Price": " $340,000 ",
                    "Date": "16-Dec-20",
                    "PSM": "3,692"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 33 BEDOK SOUTH AVENUE 2",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "98",
                    "PSF": "403",
                    "Price": " $425,000 ",
                    "Date": "16-Dec-20",
                    "PSM": "4,338"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 807B CHAI CHEE ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "93",
                    "PSF": "602",
                    "Price": " $603,000 ",
                    "Date": "14-Dec-20",
                    "PSM": "6,480"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 705 BEDOK NORTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "439",
                    "Price": " $435,000 ",
                    "Date": "11-Dec-20",
                    "PSM": "4,725"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 657 JALAN TENAGA",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "107",
                    "PSF": "460",
                    "Price": " $530,000 ",
                    "Date": "11-Dec-20",
                    "PSM": "4,951"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 807A CHAI CHEE ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "93",
                    "PSF": "637",
                    "Price": " $638,000 ",
                    "Date": "11-Dec-20",
                    "PSM": "6,857"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 41 BEDOK SOUTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "IMPROVED",
                    "Size": "84",
                    "PSF": "355",
                    "Price": " $320,500 ",
                    "Date": "11-Dec-20",
                    "PSM": "3,821"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 185 BEDOK NORTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "92",
                    "PSF": "611",
                    "Price": " $605,000 ",
                    "Date": "11-Dec-20",
                    "PSM": "6,577"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 807C CHAI CHEE ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "93",
                    "PSF": "629",
                    "Price": " $630,000 ",
                    "Date": "11-Dec-20",
                    "PSM": "6,771"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 111 LENGKONG TIGA",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "104",
                    "PSF": "581",
                    "Price": " $650,000 ",
                    "Date": "11-Dec-20",
                    "PSM": "6,254"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 404 BEDOK NORTH AVENUE 3",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "422",
                    "Price": " $418,000 ",
                    "Date": "9-Dec-20",
                    "PSM": "4,542"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 124 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "SIMPLIFIED",
                    "Size": "84",
                    "PSF": "393",
                    "Price": " $355,000 ",
                    "Date": "9-Dec-20",
                    "PSM": "4,230"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 521 BEDOK NORTH AVENUE 1",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "356",
                    "Price": " $352,000 ",
                    "Date": "9-Dec-20",
                    "PSM": "3,832"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 149 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "SIMPLIFIED",
                    "Size": "84",
                    "PSF": "415",
                    "Price": " $375,000 ",
                    "Date": "9-Dec-20",
                    "PSM": "4,467"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 180 BEDOK NORTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "92",
                    "PSF": "505",
                    "Price": " $500,000 ",
                    "Date": "9-Dec-20",
                    "PSM": "5,436"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 133 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "103",
                    "PSF": "379",
                    "Price": " $420,000 ",
                    "Date": "7-Dec-20",
                    "PSM": "4,080"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 422 BEDOK NORTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "434",
                    "Price": " $430,000 ",
                    "Date": "4-Dec-20",
                    "PSM": "4,672"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 69 BEDOK SOUTH AVENUE 3",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "399",
                    "Price": " $395,000 ",
                    "Date": "4-Dec-20",
                    "PSM": "4,295"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 125 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "SIMPLIFIED",
                    "Size": "84",
                    "PSF": "420",
                    "Price": " $380,000 ",
                    "Date": "4-Dec-20",
                    "PSM": "4,521"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 808A CHAI CHEE ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "93",
                    "PSF": "597",
                    "Price": " $598,000 ",
                    "Date": "4-Dec-20",
                    "PSM": "6,426"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 808C CHAI CHEE ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "93",
                    "PSF": "624",
                    "Price": " $625,000 ",
                    "Date": "4-Dec-20",
                    "PSM": "6,717"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 712 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "93",
                    "PSF": "408",
                    "Price": " $408,000 ",
                    "Date": "1-Dec-20",
                    "PSM": "4,392"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 115 LENGKONG TIGA",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "104",
                    "PSF": "571",
                    "Price": " $639,000 ",
                    "Date": "1-Dec-20",
                    "PSM": "6,146"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 80 BEDOK NORTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "91",
                    "PSF": "373",
                    "Price": " $365,000 ",
                    "Date": "1-Dec-20",
                    "PSM": "4,015"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 220B BEDOK CENTRAL",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "97",
                    "PSF": "699",
                    "Price": " $730,000 ",
                    "Date": "1-Dec-20",
                    "PSM": "7,524"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 774 BEDOK RESERVOIR VIEW",
                    "Type": "4-ROOM",
                    "Model": "PREMIUM APARTMENT",
                    "Size": "105",
                    "PSF": "491",
                    "Price": " $555,000 ",
                    "Date": "30-Nov-20",
                    "PSM": "5,285"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 808C CHAI CHEE ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "93",
                    "PSF": "627",
                    "Price": " $628,000 ",
                    "Date": "30-Nov-20",
                    "PSM": "6,749"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 136 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "SIMPLIFIED",
                    "Size": "88",
                    "PSF": "465",
                    "Price": " $440,000 ",
                    "Date": "27-Nov-20",
                    "PSM": "5,005"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 165 BEDOK SOUTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "SIMPLIFIED",
                    "Size": "83",
                    "PSF": "459",
                    "Price": " $410,000 ",
                    "Date": "27-Nov-20",
                    "PSM": "4,941"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 651 JALAN TENAGA",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "108",
                    "PSF": "422",
                    "Price": " $490,000 ",
                    "Date": "25-Nov-20",
                    "PSM": "4,542"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 87 BEDOK NORTH STREET 4",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "89",
                    "PSF": "366",
                    "Price": " $350,000 ",
                    "Date": "25-Nov-20",
                    "PSM": "3,940"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 106 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "91",
                    "PSF": "376",
                    "Price": " $368,000 ",
                    "Date": "23-Nov-20",
                    "PSM": "4,047"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 163 BEDOK SOUTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "SIMPLIFIED",
                    "Size": "83",
                    "PSF": "421",
                    "Price": " $376,000 ",
                    "Date": "23-Nov-20",
                    "PSM": "4,532"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 703 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "93",
                    "PSF": "405",
                    "Price": " $405,000 ",
                    "Date": "20-Nov-20",
                    "PSM": "4,359"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 428 BEDOK NORTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "387",
                    "Price": " $383,000 ",
                    "Date": "20-Nov-20",
                    "PSM": "4,166"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 808A CHAI CHEE ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "93",
                    "PSF": "634",
                    "Price": " $635,000 ",
                    "Date": "20-Nov-20",
                    "PSM": "6,824"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 41 CHAI CHEE STREET",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "91",
                    "PSF": "427",
                    "Price": " $418,000 ",
                    "Date": "20-Nov-20",
                    "PSM": "4,596"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 181 BEDOK NORTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "92",
                    "PSF": "581",
                    "Price": " $575,000 ",
                    "Date": "18-Nov-20",
                    "PSM": "6,254"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 65 NEW UPPER CHANGI ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "374",
                    "Price": " $370,000 ",
                    "Date": "18-Nov-20",
                    "PSM": "4,026"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 701 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "93",
                    "PSF": "430",
                    "Price": " $430,000 ",
                    "Date": "18-Nov-20",
                    "PSM": "4,629"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 183 BEDOK NORTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "92",
                    "PSF": "618",
                    "Price": " $612,000 ",
                    "Date": "16-Nov-20",
                    "PSM": "6,652"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 807A CHAI CHEE ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "93",
                    "PSF": "614",
                    "Price": " $615,000 ",
                    "Date": "16-Nov-20",
                    "PSM": "6,609"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 508 BEDOK NORTH AVENUE 3",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "362",
                    "Price": " $358,000 ",
                    "Date": "13-Nov-20",
                    "PSM": "3,897"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 53 NEW UPPER CHANGI ROAD",
                    "Type": "4-ROOM",
                    "Model": "IMPROVED",
                    "Size": "84",
                    "PSF": "459",
                    "Price": " $415,000 ",
                    "Date": "13-Nov-20",
                    "PSM": "4,941"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 166 BEDOK SOUTH AVENUE 3",
                    "Type": "4-ROOM",
                    "Model": "SIMPLIFIED",
                    "Size": "83",
                    "PSF": "470",
                    "Price": " $420,000 ",
                    "Date": "13-Nov-20",
                    "PSM": "5,059"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 169 BEDOK SOUTH AVENUE 3",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "105",
                    "PSF": "407",
                    "Price": " $460,000 ",
                    "Date": "13-Nov-20",
                    "PSM": "4,381"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 183 BEDOK NORTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "92",
                    "PSF": "596",
                    "Price": " $590,000 ",
                    "Date": "13-Nov-20",
                    "PSM": "6,415"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 658 JALAN TENAGA",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "106",
                    "PSF": "404",
                    "Price": " $460,000 ",
                    "Date": "13-Nov-20",
                    "PSM": "4,349"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 62 NEW UPPER CHANGI ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "424",
                    "Price": " $420,000 ",
                    "Date": "13-Nov-20",
                    "PSM": "4,564"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 524 BEDOK NORTH STREET 3",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "354",
                    "Price": " $350,000 ",
                    "Date": "11-Nov-20",
                    "PSM": "3,810"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 115 LENGKONG TIGA",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "106",
                    "PSF": "518",
                    "Price": " $590,000 ",
                    "Date": "11-Nov-20",
                    "PSM": "5,576"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 148 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "SIMPLIFIED",
                    "Size": "84",
                    "PSF": "379",
                    "Price": " $343,000 ",
                    "Date": "11-Nov-20",
                    "PSM": "4,080"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 543 BEDOK NORTH STREET 3",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "95",
                    "PSF": "352",
                    "Price": " $360,000 ",
                    "Date": "11-Nov-20",
                    "PSM": "3,789"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 404 BEDOK NORTH AVENUE 3",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "409",
                    "Price": " $405,000 ",
                    "Date": "11-Nov-20",
                    "PSM": "4,402"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 524 BEDOK NORTH STREET 3",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "348",
                    "Price": " $345,000 ",
                    "Date": "11-Nov-20",
                    "PSM": "3,746"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 36 BEDOK SOUTH AVENUE 2",
                    "Type": "4-ROOM",
                    "Model": "IMPROVED",
                    "Size": "84",
                    "PSF": "398",
                    "Price": " $360,000 ",
                    "Date": "6-Nov-20",
                    "PSM": "4,284"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 423 BEDOK NORTH AVENUE 1",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "91",
                    "PSF": "393",
                    "Price": " $385,000 ",
                    "Date": "6-Nov-20",
                    "PSM": "4,230"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 7 BEDOK SOUTH AVENUE 2",
                    "Type": "4-ROOM",
                    "Model": "IMPROVED",
                    "Size": "82",
                    "PSF": "395",
                    "Price": " $348,000 ",
                    "Date": "6-Nov-20",
                    "PSM": "4,252"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 147 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "SIMPLIFIED",
                    "Size": "84",
                    "PSF": "384",
                    "Price": " $347,000 ",
                    "Date": "4-Nov-20",
                    "PSM": "4,133"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 220B BEDOK CENTRAL",
                    "Type": "4-ROOM",
                    "Model": "Model A",
                    "Size": "94",
                    "PSF": "678",
                    "Price": " $685,000 ",
                    "Date": "4-Nov-20",
                    "PSM": "7,298"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 170 BEDOK SOUTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "SIMPLIFIED",
                    "Size": "84",
                    "PSF": "431",
                    "Price": " $390,000 ",
                    "Date": "4-Nov-20",
                    "PSM": "4,639"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 115 BEDOK NORTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "98",
                    "PSF": "361",
                    "Price": " $380,000 ",
                    "Date": "1-Nov-20",
                    "PSM": "3,886"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 108 BEDOK RESERVOIR ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "91",
                    "PSF": "378",
                    "Price": " $370,000 ",
                    "Date": "1-Nov-20",
                    "PSM": "4,069"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 428 BEDOK NORTH ROAD",
                    "Type": "4-ROOM",
                    "Model": "NEW GENERATION",
                    "Size": "92",
                    "PSF": "404",
                    "Price": " $400,000 ",
                    "Date": "1-Nov-20",
                    "PSM": "4,349"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 52 NEW UPPER CHANGI ROAD",
                    "Type": "4-ROOM",
                    "Model": "IMPROVED",
                    "Size": "84",
                    "Price": " $349,000 ",
                    "Date": "1-Nov-20",
                    "PSM": "4,155"
                }
            ];
export const fiveRm = [
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 220C BEDOK CENTRAL",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "112",
                    "PSF": "695",
                    "Price": " $838,000 ",
                    "Date": "18-Jan-21",
                    "PSM": "7,481"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 807C CHAI CHEE ROAD",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "113",
                    "PSF": "650",
                    "Price": " $790,000 ",
                    "Date": "18-Jan-21",
                    "PSM": "6,997"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 53 CHAI CHEE STREET",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "121",
                    "PSF": "369",
                    "Price": " $480,000 ",
                    "Date": "18-Jan-21",
                    "PSM": "3,972"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 769 BEDOK RESERVOIR VIEW",
                    "Type": "5-ROOM",
                    "Model": "PREMIUM APARTMENT",
                    "Size": "117",
                    "PSF": "494",
                    "Price": " $622,000 ",
                    "Date": "18-Jan-21",
                    "PSM": "5,317"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 146 BEDOK RESERVOIR ROAD",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "122",
                    "PSF": "366",
                    "Price": " $480,000 ",
                    "Date": "15-Jan-21",
                    "PSM": "3,940"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 137 BEDOK NORTH AVENUE 3",
                    "Type": "5-ROOM",
                    "Model": "STANDARD",
                    "Size": "117",
                    "PSF": "419",
                    "Price": " $528,000 ",
                    "Date": "15-Jan-21",
                    "PSM": "4,510"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 171 BEDOK SOUTH ROAD",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "122",
                    "PSF": "388",
                    "Price": " $510,000 ",
                    "Date": "15-Jan-21",
                    "PSM": "4,176"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 128 BEDOK RESERVOIR ROAD",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "122",
                    "PSF": "381",
                    "Price": " $500,000 ",
                    "Date": "13-Jan-21",
                    "PSM": "4,101"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 54 NEW UPPER CHANGI ROAD",
                    "Type": "5-ROOM",
                    "Model": "STANDARD",
                    "Size": "118",
                    "PSF": "417",
                    "Price": " $530,000 ",
                    "Date": "13-Jan-21",
                    "PSM": "4,489"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 98 BEDOK NORTH AVENUE 4",
                    "Type": "5-ROOM",
                    "Model": "STANDARD",
                    "Size": "123",
                    "PSF": "408",
                    "Price": " $540,000 ",
                    "Date": "13-Jan-21",
                    "PSM": "4,392"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 761 BEDOK RESERVOIR VIEW",
                    "Type": "5-ROOM",
                    "Model": "PREMIUM APARTMENT",
                    "Size": "115",
                    "PSF": "495",
                    "Price": " $612,000 ",
                    "Date": "13-Jan-21",
                    "PSM": "5,328"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 669 JALAN DAMAI",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "124",
                    "PSF": "472",
                    "Price": " $630,000 ",
                    "Date": "13-Jan-21",
                    "PSM": "5,081"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 766 BEDOK RESERVOIR VIEW",
                    "Type": "5-ROOM",
                    "Model": "PREMIUM APARTMENT",
                    "Size": "115",
                    "PSF": "525",
                    "Price": " $650,000 ",
                    "Date": "11-Jan-21",
                    "PSM": "5,651"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 553 BEDOK NORTH AVENUE 1",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "121",
                    "PSF": "394",
                    "Price": " $513,000 ",
                    "Date": "8-Jan-21",
                    "PSM": "4,241"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 516 BEDOK NORTH AVENUE 2",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "119",
                    "PSF": "475",
                    "Price": " $608,000 ",
                    "Date": "6-Jan-21",
                    "PSM": "5,113"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 603 BEDOK RESERVOIR ROAD",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "120",
                    "PSF": "363",
                    "Price": " $468,000 ",
                    "Date": "6-Jan-21",
                    "PSM": "3,907"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 112 BEDOK RESERVOIR ROAD",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "121",
                    "PSF": "365",
                    "Price": " $475,000 ",
                    "Date": "6-Jan-21",
                    "PSM": "3,929"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 807C CHAI CHEE ROAD",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "113",
                    "PSF": "588",
                    "Price": " $715,000 ",
                    "Date": "30-Dec-20",
                    "PSM": "6,329"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 723 BEDOK RESERVOIR ROAD",
                    "Type": "5-ROOM",
                    "Model": "Model A-MAISONETTE",
                    "Size": "137",
                    "PSF": "415",
                    "Price": " $612,000 ",
                    "Date": "28-Dec-20",
                    "PSM": "4,467"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 546 BEDOK NORTH STREET 3",
                    "Type": "5-ROOM",
                    "Model": "ADJOINED FLAT",
                    "Size": "136",
                    "PSF": "409",
                    "Price": " $598,000 ",
                    "Date": "25-Dec-20",
                    "PSM": "4,402"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 90 BEDOK NORTH STREET 4",
                    "Type": "5-ROOM",
                    "Model": "STANDARD",
                    "Size": "117",
                    "PSF": "369",
                    "Price": " $464,000 ",
                    "Date": "23-Dec-20",
                    "PSM": "3,972"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 20 BEDOK SOUTH ROAD",
                    "Type": "5-ROOM",
                    "Model": "STANDARD",
                    "Size": "117",
                    "PSF": "479",
                    "Price": " $603,000 ",
                    "Date": "23-Dec-20",
                    "PSM": "5,156"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 55 NEW UPPER CHANGI ROAD",
                    "Type": "5-ROOM",
                    "Model": "STANDARD",
                    "Size": "120",
                    "PSF": "422",
                    "Price": " $544,500 ",
                    "Date": "23-Dec-20",
                    "PSM": "4,542"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 774 BEDOK RESERVOIR VIEW",
                    "Type": "5-ROOM",
                    "Model": "PREMIUM APARTMENT",
                    "Size": "112",
                    "PSF": "432",
                    "Price": " $520,000 ",
                    "Date": "23-Dec-20",
                    "PSM": "4,650"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 406 BEDOK NORTH AVENUE 3",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "118",
                    "PSF": "488",
                    "Price": " $620,000 ",
                    "Date": "18-Dec-20",
                    "PSM": "5,253"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 112 BEDOK RESERVOIR ROAD",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "121",
                    "PSF": "388",
                    "Price": " $505,000 ",
                    "Date": "18-Dec-20",
                    "PSM": "4,176"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 670 JALAN DAMAI",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "128",
                    "PSF": "439",
                    "Price": " $605,000 ",
                    "Date": "16-Dec-20",
                    "PSM": "4,725"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 112 BEDOK NORTH ROAD",
                    "Type": "5-ROOM",
                    "Model": "STANDARD",
                    "Size": "117",
                    "PSF": "346",
                    "Price": " $435,000 ",
                    "Date": "16-Dec-20",
                    "PSM": "3,724"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 21 BEDOK SOUTH ROAD",
                    "Type": "5-ROOM",
                    "Model": "STANDARD",
                    "Size": "117",
                    "PSF": "481",
                    "Price": " $605,000 ",
                    "Date": "14-Dec-20",
                    "PSM": "5,177"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 2 CHAI CHEE ROAD",
                    "Type": "5-ROOM",
                    "Model": "Model A",
                    "Size": "133",
                    "PSF": "372",
                    "Price": " $533,000 ",
                    "Date": "11-Dec-20",
                    "PSM": "4,004"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 32 BEDOK SOUTH AVENUE 2",
                    "Type": "5-ROOM",
                    "Model": "STANDARD",
                    "Size": "118",
                    "PSF": "457",
                    "Price": " $580,000 ",
                    "Date": "11-Dec-20",
                    "PSM": "4,919"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 506 BEDOK NORTH AVENUE 3",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "119",
                    "PSF": "445",
                    "Price": " $569,000 ",
                    "Date": "9-Dec-20",
                    "PSM": "4,790"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 26 CHAI CHEE ROAD",
                    "Type": "5-ROOM",
                    "Model": "Model A",
                    "Size": "133",
                    "PSF": "496",
                    "Price": " $710,000 ",
                    "Date": "9-Dec-20",
                    "PSM": "5,339"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 603 BEDOK RESERVOIR ROAD",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "120",
                    "PSF": "341",
                    "Price": " $440,000 ",
                    "Date": "9-Dec-20",
                    "PSM": "3,671"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 405 BEDOK NORTH AVENUE 3",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "122",
                    "PSF": "513",
                    "Price": " $673,999 ",
                    "Date": "4-Dec-20",
                    "PSM": "5,522"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 772 BEDOK RESERVOIR VIEW",
                    "Type": "5-ROOM",
                    "Model": "PREMIUM APARTMENT",
                    "Size": "115",
                    "PSF": "461",
                    "Price": " $570,000 ",
                    "Date": "4-Dec-20",
                    "PSM": "4,962"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 220C BEDOK CENTRAL",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "112",
                    "PSF": "671",
                    "Price": " $808,000 ",
                    "Date": "2-Dec-20",
                    "PSM": "7,223"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 102 LENGKONG TIGA",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "129",
                    "PSF": "562",
                    "Price": " $780,000 ",
                    "Date": "1-Dec-20",
                    "PSM": "6,049"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 101 BEDOK RESERVOIR ROAD",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "123",
                    "PSF": "370",
                    "Price": " $490,000 ",
                    "Date": "30-Nov-20",
                    "PSM": "3,983"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 91 BEDOK NORTH STREET 4",
                    "Type": "5-ROOM",
                    "Model": "STANDARD",
                    "Size": "117",
                    "PSF": "320",
                    "Price": " $403,000 ",
                    "Date": "27-Nov-20",
                    "PSM": "3,444"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 516 BEDOK NORTH AVENUE 2",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "119",
                    "PSF": "477",
                    "Price": " $610,000 ",
                    "Date": "27-Nov-20",
                    "PSM": "5,134"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 97 BEDOK NORTH AVENUE 4",
                    "Type": "5-ROOM",
                    "Model": "STANDARD",
                    "Size": "123",
                    "PSF": "392",
                    "Price": " $518,000 ",
                    "Date": "27-Nov-20",
                    "PSM": "4,219"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 613 BEDOK RESERVOIR ROAD",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "121",
                    "PSF": "338",
                    "Price": " $440,000 ",
                    "Date": "25-Nov-20",
                    "PSM": "3,638"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 34 CHAI CHEE AVENUE",
                    "Type": "5-ROOM",
                    "Model": "STANDARD",
                    "Size": "119",
                    "PSF": "459",
                    "Price": " $588,000 ",
                    "Date": "25-Nov-20",
                    "PSM": "4,941"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 99 BEDOK NORTH AVENUE 4",
                    "Type": "5-ROOM",
                    "Model": "STANDARD",
                    "Size": "125",
                    "PSF": "372",
                    "Price": " $500,000 ",
                    "Date": "25-Nov-20",
                    "PSM": "4,004"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 33 CHAI CHEE AVENUE",
                    "Type": "5-ROOM",
                    "Model": "STANDARD",
                    "Size": "117",
                    "PSF": "457",
                    "Price": " $575,000 ",
                    "Date": "23-Nov-20",
                    "PSM": "4,919"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 649 JALAN TENAGA",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "121",
                    "PSF": "422",
                    "Price": " $550,000 ",
                    "Date": "23-Nov-20",
                    "PSM": "4,542"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 19 BEDOK SOUTH ROAD",
                    "Type": "5-ROOM",
                    "Model": "STANDARD",
                    "Size": "119",
                    "PSF": "498",
                    "Price": " $638,000 ",
                    "Date": "20-Nov-20",
                    "PSM": "5,360"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 417 BEDOK NORTH AVENUE 2",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "119",
                    "PSF": "473",
                    "Price": " $605,000 ",
                    "Date": "20-Nov-20",
                    "PSM": "5,091"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 621 BEDOK RESERVOIR ROAD",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "121",
                    "PSF": "373",
                    "Price": " $485,000 ",
                    "Date": "18-Nov-20",
                    "PSM": "4,015"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 128 BEDOK RESERVOIR ROAD",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "122",
                    "PSF": "347",
                    "Price": " $455,000 ",
                    "Date": "18-Nov-20",
                    "PSM": "3,735"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 51 CHAI CHEE STREET",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "121",
                    "PSF": "353",
                    "Price": " $460,000 ",
                    "Date": "13-Nov-20",
                    "PSM": "3,800"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 68 BEDOK SOUTH AVENUE 3",
                    "Type": "5-ROOM",
                    "Model": "STANDARD",
                    "Size": "123",
                    "PSF": "475",
                    "Price": " $628,000 ",
                    "Date": "13-Nov-20",
                    "PSM": "5,113"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 63 CHAI CHEE ROAD",
                    "Type": "5-ROOM",
                    "Model": "Model A",
                    "Size": "133",
                    "PSF": "363",
                    "Price": " $520,000 ",
                    "Date": "11-Nov-20",
                    "PSM": "3,907"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 407 BEDOK NORTH AVENUE 3",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "118",
                    "PSF": "472",
                    "Price": " $600,000 ",
                    "Date": "11-Nov-20",
                    "PSM": "5,081"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 648 JALAN TENAGA",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "121",
                    "PSF": "453",
                    "Price": " $590,000 ",
                    "Date": "11-Nov-20",
                    "PSM": "4,876"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 53 CHAI CHEE STREET",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "121",
                    "PSF": "366",
                    "Price": " $476,000 ",
                    "Date": "6-Nov-20",
                    "PSM": "3,940"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 14 BEDOK SOUTH AVENUE 2",
                    "Type": "5-ROOM",
                    "Model": "Model A",
                    "Size": "131",
                    "PSF": "514",
                    "Price": " $725,000 ",
                    "Date": "6-Nov-20",
                    "PSM": "5,533"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 31 BEDOK SOUTH AVENUE 2",
                    "Type": "5-ROOM",
                    "Model": "STANDARD",
                    "Size": "118",
                    "PSF": "465",
                    "Price": " $590,000 ",
                    "Date": "4-Nov-20",
                    "PSM": "5,005"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 405 BEDOK NORTH AVENUE 3",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "118",
                    "PSF": "488",
                    "Price": " $620,000 ",
                    "Date": "4-Nov-20",
                    "PSM": "5,253"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 68 BEDOK SOUTH AVENUE 3",
                    "Type": "5-ROOM",
                    "Model": "STANDARD",
                    "Size": "123",
                    "PSF": "476",
                    "Price": " $630,000 ",
                    "Date": "1-Nov-20",
                    "PSM": "5,124"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 611 BEDOK RESERVOIR ROAD",
                    "Type": "5-ROOM",
                    "Model": "IMPROVED",
                    "Size": "121",
                    "PSF": "392",
                    "Price": " $510,000 ",
                    "Date": "1-Nov-20",
                    "PSM": "4,219"
                }
            ];
export const exe = [
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 104 LENGKONG TIGA",
                    "Type": "EXECUTIVE",
                    "Model": "MAISONETTE",
                    "Size": "154",
                    "PSF": "537",
                    "Price": " $890,000 ",
                    "Date": "13-Jan-21",
                    "PSM": "5,780"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 639 BEDOK RESERVOIR ROAD",
                    "Type": "EXECUTIVE",
                    "Model": "APARTMENT",
                    "Size": "144",
                    "PSF": "499",
                    "Price": " $773,000 ",
                    "Date": "13-Jan-21",
                    "PSM": "5,371"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 644 BEDOK RESERVOIR ROAD",
                    "Type": "EXECUTIVE",
                    "Model": "APARTMENT",
                    "Size": "144",
                    "PSF": "479",
                    "Price": " $743,000 ",
                    "Date": "8-Jan-21",
                    "PSM": "5,156"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 94C BEDOK NORTH AVENUE 4",
                    "Type": "EXECUTIVE",
                    "Model": "APARTMENT",
                    "Size": "143",
                    "PSF": "481",
                    "Price": " $740,000 ",
                    "Date": "6-Jan-21",
                    "PSM": "5,177"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 639 BEDOK RESERVOIR ROAD",
                    "Type": "EXECUTIVE",
                    "Model": "APARTMENT",
                    "Size": "143",
                    "PSF": "477",
                    "Price": " $734,000 ",
                    "Date": "28-Dec-20",
                    "PSM": "5,134"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 161 BEDOK SOUTH AVENUE 3",
                    "Type": "EXECUTIVE",
                    "Model": "MAISONETTE",
                    "Size": "151",
                    "PSF": "462",
                    "Price": " $750,000 ",
                    "Date": "28-Dec-20",
                    "PSM": "4,973"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 639 BEDOK RESERVOIR ROAD",
                    "Type": "EXECUTIVE",
                    "Model": "APARTMENT",
                    "Size": "144",
                    "PSF": "473",
                    "Price": " $733,000 ",
                    "Date": "23-Dec-20",
                    "PSM": "5,091"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 721 BEDOK RESERVOIR ROAD",
                    "Type": "EXECUTIVE",
                    "Model": "MAISONETTE",
                    "Size": "147",
                    "PSF": "395",
                    "Price": " $625,000 ",
                    "Date": "11-Dec-20",
                    "PSM": "4,252"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 636 BEDOK RESERVOIR ROAD",
                    "Type": "EXECUTIVE",
                    "Model": "APARTMENT",
                    "Size": "142",
                    "PSF": "452",
                    "Price": " $690,888 ",
                    "Date": "11-Dec-20",
                    "PSM": "4,865"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 167 BEDOK SOUTH AVENUE 3",
                    "Type": "EXECUTIVE",
                    "Model": "MAISONETTE",
                    "Size": "146",
                    "PSF": "446",
                    "Price": " $700,000 ",
                    "Date": "11-Dec-20",
                    "PSM": "4,801"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 10F BEDOK SOUTH AVENUE 2",
                    "Type": "EXECUTIVE",
                    "Model": "APARTMENT",
                    "Size": "153",
                    "PSF": "468",
                    "Price": " $770,000 ",
                    "Date": "9-Dec-20",
                    "PSM": "5,038"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 137 BEDOK RESERVOIR ROAD",
                    "Type": "EXECUTIVE",
                    "Model": "MAISONETTE",
                    "Size": "143",
                    "PSF": "415",
                    "Price": " $638,000 ",
                    "Date": "9-Dec-20",
                    "PSM": "4,467"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 115 BEDOK RESERVOIR ROAD",
                    "Type": "EXECUTIVE",
                    "Model": "MAISONETTE",
                    "Size": "150",
                    "PSF": "431",
                    "Price": " $695,000 ",
                    "Date": "1-Dec-20",
                    "PSM": "4,639"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 94B BEDOK NORTH AVENUE 4",
                    "Type": "EXECUTIVE",
                    "Model": "APARTMENT",
                    "Size": "143",
                    "PSF": "475",
                    "Price": " $730,888 ",
                    "Date": "27-Nov-20",
                    "PSM": "5,113"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 130 BEDOK RESERVOIR ROAD",
                    "Type": "EXECUTIVE",
                    "Model": "APARTMENT",
                    "Size": "150",
                    "PSF": "390",
                    "Price": " $630,000 ",
                    "Date": "13-Nov-20",
                    "PSM": "4,198"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 721 BEDOK RESERVOIR ROAD",
                    "Type": "EXECUTIVE",
                    "Model": "APARTMENT",
                    "Size": "149",
                    "PSF": "412",
                    "Price": " $660,000 ",
                    "Date": "6-Nov-20",
                    "PSM": "4,435"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 145 BEDOK RESERVOIR ROAD",
                    "Type": "EXECUTIVE",
                    "Model": "MAISONETTE",
                    "Size": "143",
                    "PSF": "422",
                    "Price": " $650,000 ",
                    "Date": "28-Oct-20",
                    "PSM": "4,542"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 721 BEDOK RESERVOIR ROAD",
                    "Type": "EXECUTIVE",
                    "Model": "MAISONETTE",
                    "Size": "148",
                    "PSF": "455",
                    "Price": " $725,000 ",
                    "Date": "26-Oct-20",
                    "PSM": "4,898"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 167 BEDOK SOUTH AVENUE 3",
                    "Type": "EXECUTIVE",
                    "Model": "MAISONETTE",
                    "Size": "152",
                    "PSF": "471",
                    "Price": " $770,000 ",
                    "Date": "26-Oct-20",
                    "PSM": "5,070"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 636 BEDOK RESERVOIR ROAD",
                    "Type": "EXECUTIVE",
                    "Model": "APARTMENT",
                    "Size": "142",
                    "PSF": "484",
                    "Price": " $738,888 ",
                    "Date": "21-Oct-20",
                    "PSM": "5,210"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 167 BEDOK SOUTH AVENUE 3",
                    "Type": "EXECUTIVE",
                    "Model": "MAISONETTE",
                    "Size": "146",
                    "PSF": "449",
                    "Price": " $705,000 ",
                    "Date": "16-Oct-20",
                    "PSM": "4,833"
                },
                {
                    "Town": "BEDOK",
                    "StreetName": "BLK 721 BEDOK RESERVOIR ROAD",
                    "Type": "EXECUTIVE",
                    "Model": "MAISONETTE",
                    "Size": "148",
                    "PSF": "457",
                    "Price": " $728,000 ",
                    "Date": "14-Oct-20",
                    "PSM": "4,919"
                }
            ];