
const ThankYouPage = () => {
    return (
      <div>
        <div style={{ marginTop: 35, padding: 15, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <h2 style={{ textAlign: 'center', marginBottom: 35, color: 'grey' }}>
              Thank you for reaching out to us!
          </h2>
            <div style={{ textAlign: 'center', marginBottom: 35, color: 'grey' }}>
                Our team will contact you shortly. 
                However, if you have any urgent inquiries you may contact us directly @ 
                <a style={{ color: 'blue' }} href={"https://wa.me/+6584845999"}> 8484 5999</a>
            </div>
        </div>
      <div style={{ height: 100 }}> </div>
      </div>
    );
  }
  
  export default ThankYouPage;
  